/** @format */

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";

import * as EffectorReact from "effector-react";

import * as Store from "../../stores/videos/store";

const LoginDialog = () => {
  const isLoggedIn = EffectorReact.useStore(Store.$isLoggedIn);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      PaperProps={{ elevation: 4 }}
      open={!isLoggedIn}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Войти</DialogTitle>

      <DialogContent>
        <form>
          <FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel color="secondary">Логин</InputLabel>
              <OutlinedInput
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                autoComplete="off"
                autoFocus
                color="secondary"
                type="text"
                labelWidth={50}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth variant="outlined">
              <InputLabel color="secondary">Пароль</InputLabel>
              <OutlinedInput
                autoComplete="off"
                value={password}
                color="secondary"
                type={showPassword ? "text" : "password"}
                labelWidth={60}
                onChange={(event) => setPassword(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onMouseDown={() => setShowPassword(true)}
                      onMouseUp={() => setShowPassword(false)}
                      onMouseLeave={() => setShowPassword(false)}
                      edge="end"
                    >
                      {showPassword ? (
                        <MatIcons.Visibility />
                      ) : (
                        <MatIcons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl margin="dense">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => {
                  Store.setUsername(username);
                  Store.setPassword(password);
                  setUsername("");
                  setPassword("");
                }}
              >
                Войти
              </Button>
            </FormControl>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions disableSpacing></DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
