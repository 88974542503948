import React, { FormEvent, useEffect } from "react";
import {
  TextField,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
} from "@material-ui/core";
import { FormGroup, BlockedButton } from "../styled";
import { allUserRoles } from "../../../../constant";
import { closeModal } from "../../../../stores/modal";
import {
  $formState,
  formStateResetEv,
  formStateChangedEv,
  blockUserFx,
  updateUserFx,
  $users,
} from "../../../../stores/users";
import { useStore } from "effector-react";
import { UserForm } from "../";
import { toast } from "react-toastify";

export function UserEdit({ userId }: UserForm) {
  const values = useStore($formState);
  const users = useStore($users);
  const isBlockOrUnblockPending = useStore(blockUserFx.pending);
  const user = users.find((user) => user.id === userId);
  const shouldUpdateUserName = user?.username !== values.userName
  const shouldUpdatePassword = Number(values.password?.trim().length) > 0
  const shouldUpdateRoles = JSON.stringify(values?.roles) !== JSON.stringify(user?.roles)

  useEffect(() => {
    if (user) {
      formStateChangedEv({
        userName: user?.username,
        roles: user?.roles,
      });
    }
    return () => formStateResetEv();
  }, [user]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (userId) {
        if (!shouldUpdateUserName) delete values["userName"];
        if (!shouldUpdateRoles) delete values["roles"];

        await updateUserFx({ id: userId, ...values });

        toast("Успешно отредактирован", { type: "success" });
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeRoles = (role: string) => {
    const roles = [...(values.roles || [])];
    const findIndex = roles?.findIndex((r) => r === role);
    if (findIndex === -1) roles.push(role as never);
    else roles.splice(findIndex, 1);
    formStateChangedEv({ roles });
  };

  const handleBlockUser = () => {
    if (user) {
      const isBlocked = !user.isBlocked;
      const isConfirm = confirm(
        `Вы уверены, что хотите ${
          isBlocked ? "заблокировать" : "разблокировать"
        } пользователя?`
      );
      if (isConfirm) void blockUserFx({ id: String(user.id), isBlocked });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <TextField
          size="small"
          label="Логин"
          value={values.userName}
          defaultValue={user?.username || ""}
          variant="outlined"
          onChange={(e) =>
            formStateChangedEv({ userName: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          size="small"
          label="Пароль"
          value={values.password || ""}
          variant="outlined"
          type="password"
          onChange={(e) =>
            formStateChangedEv({ password: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Роль</FormLabel>
        {allUserRoles.map((role) => (
          <FormControlLabel
            key={role}
            control={
              <Checkbox
                checked={(values?.roles || []).includes(role as never)}
                onChange={() => handleChangeRoles(role)}
              />
            }
            label={role}
          />
        ))}
      </FormGroup>
      <FormGroup>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BlockedButton fullWidth onClick={handleBlockUser}>
              {isBlockOrUnblockPending ? 'Идет запрос...' : Boolean(user?.isBlocked) ? "Разблокировать пользователя" : "Заблокировать пользователя"}
            </BlockedButton>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" fullWidth onClick={() => closeModal()}>
              Отменить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!shouldUpdateUserName && !shouldUpdateRoles && !shouldUpdatePassword}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
}
