import React, { useEffect, useMemo, useState } from 'react'
import { $users, getUsersFx } from '../../../stores/users'
import { useStore } from 'effector-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import * as Icons from '@material-ui/icons'
import Chip from '@material-ui/core/Chip'
import { Loading, TableHead, Blocked, Roles } from './styled'
import { UserChangelogTable } from '../user-changelog-table'
import { UserForm } from '../user-form'
import { openModal } from '../../../stores/modal'
import { types } from '../../../api'


export interface UsersTable {
  isAdmin: boolean;
}

export function UsersTable({ isAdmin }: UsersTable) {
  const [userName, setUserName] = useState('')
  const users = useStore($users)
  const isLoading = useStore(getUsersFx.pending) && users.length === 0
  const filteredUsers = useMemo(
    () =>
      users.filter((user) =>
        (user.username || '')
          .trim()
          .toLowerCase()
          .includes(userName.trim().toLowerCase()),
      ),
    [users, userName],
  )
  const isEmpty = filteredUsers.length === 0 && !isLoading

  useEffect(() => {
    void getUsersFx()
  }, [])

  const handleOpenUserChangeLog = (
    logs: types.UserHistoricalChange[],
    userName: string,
  ) => {
    openModal(<UserChangelogTable logs={logs} />, {
      title: `Изменения связанные с пользователем "${userName}"`,
      modalProps: { maxWidth: 'sm' },
    })
  }

  const handleOpenEditUserForm = (user: types.UserResponse) => {
    openModal(<UserForm userId={String(user.id)} />, {
      title: `Редактирование пользователя "${user.username}"`,
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                placeholder='ЛОГИН'
                value={userName}
                onChange={(e) => setUserName(e.currentTarget.value)}
                autoFocus
                disabled={users.length === 0}
              />
            </TableCell>
            <TableCell align='center'>Заблокирован</TableCell>
            <TableCell align='center'>Роли</TableCell>
            <TableCell align='center' style={{ whiteSpace: 'nowrap' }}>
              Дата регистрации
            </TableCell>
            <TableCell align='center'>
              Автор
            </TableCell>
            <TableCell align='center'>Изменения</TableCell>
            {isAdmin && <TableCell align='right'>Редактирование</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {(isEmpty || isLoading) && (
            <TableRow>
              <TableCell colSpan={6}>
                {isEmpty && (
                  <Typography
                    variant='h6'
                    gutterBottom
                    component='div'
                    align='center'
                  >
                    {users.length === 0 &&
                      !userName &&
                      'Данные по пользователям отсутсвуют'}
                    {userName &&
                      `Пользователь с именем "${userName}" не найден`}
                  </Typography>
                )}
                {isLoading && (
                  <Loading>
                    <CircularProgress color='secondary' />
                  </Loading>
                )}
              </TableCell>
            </TableRow>
          )}
          {filteredUsers.map((user) => {
            const shouldShowChangeLog =
              (user?.historicalChanges || []).length > 0
            const createDate = format(
              new Date(String(user.creationDateTime)),
              'dd.MM.yyyy, hh:mm',
            )
            return (
              <TableRow key={String(user.id)}>
                <TableCell>{user.username}</TableCell>
                <TableCell align='center'>
                  {user.isBlocked && <Blocked label='Заблокирован' />}
                </TableCell>
                <TableCell align='center'>
                  <Roles>
                    {user.roles?.map((role) => (
                      <Chip
                        key={role}
                        variant='outlined'
                        size='small'
                        label={role}
                      />
                    ))}
                  </Roles>
                </TableCell>
                <TableCell align='center'>{createDate}</TableCell>
                <TableCell align='center'>{user.author}</TableCell>
                <TableCell align='center'>
                  {shouldShowChangeLog && (
                    <Tooltip
                      title={`Посмотреть изменения связанные с пользователем "${user.username}"`}
                    >
                      <IconButton
                        onClick={() =>
                          handleOpenUserChangeLog(
                            user.historicalChanges || [],
                            user.username || '',
                          )
                        }
                      >
                        <Icons.MenuBook />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                {isAdmin && (
                  <TableCell align='right'>
                    <Tooltip
                      title={`Изменить данные пользователя "${user.username}"`}
                    >
                      <IconButton onClick={() => handleOpenEditUserForm(user)}>
                        <Icons.Edit />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
