/** @format */

import React from "react";
import { styles } from "../../app/theme";
import {
  Backdrop,
  Popper,
  Paper,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  AppBar,
  Toolbar,
  ListItemSecondaryAction,
  Checkbox,
  ListItemIcon,
} from "@material-ui/core";
import * as Search from "../../stores/videos/search";
import * as Store from "../../stores/videos/store";
import { useStore } from "effector-react";
import { Skeleton } from "@material-ui/lab";
import * as Utils from "./utils";

const SearchPopper = () => {
  const classes = styles();
  const searchResults = useStore(Search.$searchResults);
  const searching = useStore(Search.$searching);
  const anchorEl = useStore(Search.$anchorEl);
  const post = useStore(Search.$post);
  const index = useStore(Search.$index);

  const skeletonArray = [];

  for (let s = 0; s < 10; s++)
    skeletonArray.push(<Skeleton width={300} animation="wave" key={s} />);

  let timeout = null;

  const handleTimeout = (event) => {
    clearTimeout(timeout);
    timeout = setTimeout(
      (term) => {
        Search.setQuery(term);
      },
      500,
      event.target.value
    );
  };

  const checkboxHandleChange = (event, match) => {
    let temp = { ...post };

    event.target.checked
      ? (temp.olimpIds = {
          ...temp.olimpIds,
          ...{ [match.id]: match.nameRu + " @ " + match.start },
        })
      : delete temp.olimpIds[match.id];

    Search.setPost(temp);
  };

  const checkboxUpdateOlimpIds = () => {
    Store.update({
      id: post.id,
      index: index,
      key: "olimpIds",
      value: post.olimpIds,
    });
    Utils.closeSearch();
  };

  const updateOlimpIds = (match) => {
    const olimpIds = {
      ...post.olimpIds,
      ...{ [match.id]: match.nameRu + " @ " + match.start },
    };

    Store.update({
      id: post.id,
      index: index,
      key: "olimpIds",
      value: olimpIds,
    });
    Utils.closeSearch();
  };

  return (
    <Backdrop
      invisible
      style={{ zIndex: 0 }}
      open={Boolean(anchorEl)}
      onClick={Utils.closeSearch}
    >
      <Popper
        onClick={(event) => event.stopPropagation()}
        style={{ zIndex: 1 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper elevation={3} className={classes.paper}>
          <TextField
            style={{ marginBottom: 10 }}
            type="search"
            variant="outlined"
            size="small"
            color="secondary"
            fullWidth
            autoFocus
            //onInputCapture
            onChange={(event) => handleTimeout(event)}
          />
          {searching && <div>{skeletonArray}</div>}

          {!searchResults.length ? (
            !searching ? (
              <div>Попробуйте ввести название вручную</div>
            ) : (
              <div></div>
            )
          ) : (
            <List dense disablePadding>
              {searchResults
                //.filter(match => !Object.keys(post.olimpIds).includes(match.id))
                .sort((a, b) => a.start - b.start)
                .map((match, idx) => (
                  <ListItem
                    disableGutters
                    onClick={() => updateOlimpIds(match)}
                    button
                    key={`${match.id}-${idx}`}
                    // className={classes.noPadding}
                  >
                    <ListItemIcon>
                      {match.live ? (
                        <div className={classes.live}>LIVE</div>
                      ) : (
                        <div className={classes.line}>LINE</div>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.noMargin}
                      primary={`${Utils.bothFormater(match.start)} - ${
                        match.nameRu
                          ? match.nameRu.split("@")[0].trim()
                          : match.nameEn.split("@")[0].trim()
                      } [${match.id}]`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        checked={post.olimpIds.hasOwnProperty(match.id)}
                        size="small"
                        onChange={(event) => checkboxHandleChange(event, match)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          )}
        </Paper>
        <AppBar
          // style={{ top: 'auto' }}
          classes={{ positionFixed: classes.appBar }}
          elevation={0}
          position="fixed"
          color="transparent"
        >
          <Toolbar className={classes.toolBar} variant="dense">
            <Button
              variant="contained"
              size="small"
              className={classes.ok}
              onClick={checkboxUpdateOlimpIds}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.cancel}
              onClick={Utils.closeSearch}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
      </Popper>
    </Backdrop>
  );
};

export default SearchPopper;
