import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import MuiAppBar from "../features/header/app-bar";
import { myLight } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { pathsWithRules } from "../constant";
import * as Pages from "../pages";
import { Modal } from "../features/modal";
import { ToastContainer } from "react-toastify";
import * as Store from "../stores/videos/store";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  useEffect(() => {
    if ("auth" in localStorage) {
      const auth = JSON.parse(localStorage.getItem("auth") || "");
      Store.setAuth(auth);
    }
    if ("fetchParams" in localStorage) {
      Store.setFetchParams(
        JSON.parse(localStorage.getItem("fetchParams") || "")
      );
    }
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={myLight}>
        <CssBaseline />
        <MuiAppBar />
        <Routes>
          <Route path={pathsWithRules.main.url()} element={<Pages.Main />} />
          <Route path={pathsWithRules.users.url()} element={<Pages.Users />} />
          <Route path={pathsWithRules.rules.url()} element={<Pages.Rules />} />
        </Routes>
        <Modal />
        <ToastContainer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
