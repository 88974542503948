/** @format */

import React from "react";
import { useStore } from "effector-react";
import { styles } from "../../app/theme";
import * as Platform from "../../stores/videos/platform";
import * as Store from "../../stores/videos/store";
import * as Utils from "./utils";
import {
  Backdrop,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { $platforms } from "../../stores/videos/store";

const PlatformPopper = () => {
  const classes = styles();
  const anchorEl = useStore(Platform.$anchorEl);
  const post = useStore(Platform.$post);
  const index = useStore(Platform.$index);
  const platforms = useStore($platforms);

  return (
    <Backdrop
      invisible
      style={{ zIndex: 0 }}
      open={Boolean(anchorEl)}
      onClick={Utils.closePlatform}
    >
      <Popper
        onClick={(event) => event.stopPropagation()}
        style={{ zIndex: 1 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper elevation={3} style={{ overflowY: "auto", padding: 10 }}>
          <List dense disablePadding>
            {platforms.filter(
              (platform) => !post.allowedPlatforms.includes(platform)
            ).length
              ? platforms
                  .filter(
                    (platform) => !post.allowedPlatforms.includes(platform)
                  )
                  .map((platform) => (
                    <ListItem
                      disableGutters
                      onClick={() => {
                        let temp = { ...post };
                        temp.allowedPlatforms = [
                          ...temp.allowedPlatforms,
                          platform,
                        ];

                        Platform.setPost(temp);

                        Store.update({
                          id: post.id,
                          index: index,
                          key: "allowedPlatforms",
                          value: temp.allowedPlatforms,
                        });
                      }}
                      button
                      key={platform}
                      className={classes.noPadding}
                    >
                      <ListItemText
                        className={classes.noMargin}
                        primary={`${platform}`}
                      />
                    </ListItem>
                  ))
              : "Все доступные платформы уже добавлены!"}
          </List>
        </Paper>
      </Popper>
    </Backdrop>
  );
};

export default PlatformPopper;
