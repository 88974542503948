import * as Effector from "effector";
import axios from "axios";
import { $auth } from "./store";

axios.defaults.baseURL = "admin";

export const fetchSearchResults = Effector.createEffect({
  handler: async ({ sportId, start, searchSize, page, query }) => {
    const res = await axios({
      method: "post",
      url: "bind/search",
      auth: $auth.getState(),
      data: {
        sportIds: sportId,
        start: start,
        size: searchSize,
        page: page,
        query: query,
      },
    });
    return res.data;
  },
});

export const setAnchorEl = Effector.createEvent();
export const resetAnchorEl = Effector.createEvent();
export const $anchorEl = Effector.createStore(null)
  .on(setAnchorEl, (_state, data) => data)
  .reset(resetAnchorEl);

export const setPost = Effector.createEvent();
export const resetPost = Effector.createEvent();
export const $post = Effector.createStore({})
  .on(setPost, (_state, data) => data)
  .reset(resetPost);

export const setIndex = Effector.createEvent();
export const resetIndex = Effector.createEvent();
export const $index = Effector.createStore(null)
  .on(setIndex, (_state, data) => data)
  .reset(resetIndex);

export const $searchResults = Effector.createStore([]);
export const setSearchResults = Effector.createEvent();
export const resetSearchResults = Effector.createEvent();

export const resetSearchParams = Effector.createEvent();

$searchResults
  .on(setSearchResults, (_state, searchResults) => searchResults)
  .on(fetchSearchResults.doneData, (state, data) => data.content)
  .reset(resetSearchResults);

export const $sportId = Effector.createStore(null);
export const setSportId = Effector.createEvent();
export const resetSportId = Effector.createEvent();

$sportId
  .on(setSportId, (_state, data) => data)
  .on(setPost, (_State, { sportId }) => sportId)
  .reset(resetSportId)
  .reset(resetSearchParams);

export const $start = Effector.createStore(null);
export const setStart = Effector.createEvent();
export const resetStart = Effector.createEvent();

$start
  .on(setStart, (_state, data) => data)
  .on(setPost, (_State, data) => data.startTime)
  .reset(resetStart)
  .reset(resetSearchParams);

export const $searchSize = Effector.createStore(1000);
export const setSearchSize = Effector.createEvent();
export const resetSearchSize = Effector.createEvent();

$searchSize
  .on(setSearchSize, (_state, data) => data)
  .reset(resetSearchSize)
  .reset(resetSearchParams);

export const $page = Effector.createStore(1);
export const setPage = Effector.createEvent();
export const resetPage = Effector.createEvent();

$page
  .on(setPage, (_state, data) => data)
  .reset(resetPage)
  .reset(resetSearchParams);

export const $query = Effector.createStore("");
export const setQuery = Effector.createEvent();
export const resetQuery = Effector.createEvent();

$query
  .on(setQuery, (_state, data) => data)
  .reset(resetQuery)
  .reset(resetSearchParams);

const $searchParams = Effector.combine({
  sportId: $sportId,
  start: $start,
  searchSize: $searchSize,
  page: $page,
  query: $query,
});

$searchParams.updates.watch((state) => {
  if ((state.sportId && state.start) || state.query.length > 3)
    fetchSearchResults(state);
});

export const $searching = Effector.createStore(false);
export const setSearching = Effector.createEvent();

$searching
  .on(setSearching, (_state, searching) => searching)
  .on(fetchSearchResults.pending, (state, pending) => pending);
