import React from "react";
import { Box, Button, Popper } from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import { reset } from "../../stores/videos/store";
import {
  deleteCustomVideo,
  openCustomVideoDialog,
} from "../../stores/videos/custom-video";
import { styles } from "../../app/theme";
import { openVideoPlayer } from "../../stores/videos/video-player";

const VideoActions = ({ post }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = styles();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getEmptyBlock = (width) => <div style={{ width }} />;

  return (
    <div className={classes.actions}>
      {post.url ? (
        <MatIcons.Tv
          color="action"
          titleAccess="Воспроизвести трансляцию"
          onClick={() => openVideoPlayer(post.url)}
        />
      ) : (
        getEmptyBlock(26.5)
      )}
      {post.allowResetManualUpdates ? (
        <MatIcons.Restore
          color="action"
          titleAccess="Откатить изменения"
          onClick={() => reset(post.id)}
          style={{ marginRight: 6 }}
        />
      ) : (
        getEmptyBlock(26.5)
      )}
      {post.isCustomEntity ? (
        <>
          <MatIcons.Edit
            color="action"
            titleAccess="Изменить"
            onClick={() => openCustomVideoDialog(post)}
          />
          <MatIcons.Delete
            color="error"
            titleAccess="Удалить"
            onClick={handleClick}
            style={{ marginRight: 0 }}
          />
          <Popper
            id="simple-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
          >
            <Box className={classes.deleteConfirmPopper}>
              <div>Вы уверены?</div>
              <div>Это действие нельзя будет отменить</div>
              <div>
                <Button
                  variant="outlined"
                  className="error"
                  style={{
                    marginRight: 15,
                    background: "#f52323",
                    color: "white",
                  }}
                  onClick={() => {
                    deleteCustomVideo(post.streamId);
                    setAnchorEl(null);
                  }}
                >
                  Да
                </Button>
                <Button variant="outlined" onClick={() => setAnchorEl(null)}>
                  Нет
                </Button>
              </div>
            </Box>
          </Popper>
        </>
      ) : (
        getEmptyBlock(43)
      )}
    </div>
  );
};

export default VideoActions;
