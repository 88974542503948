import React from "react";
import { Box, Grid } from "@material-ui/core";
import { UsersTable } from "../features/users";
import { UserCreateButton } from "../features/users/user-create-button";
import { $user } from "../stores/user";
import { useStore } from "effector-react";

export function Users() {
  const user = useStore($user);
  const isAdmin = Boolean(user?.roles?.includes("ADMIN"));

  return (
    <Box style={{ padding: 10 }}>
      <Grid container spacing={2} direction="column">
        {isAdmin && (
          <Grid item>
            <UserCreateButton />
          </Grid>
        )}
        <Grid item>
          <UsersTable isAdmin={isAdmin} />
        </Grid>
      </Grid>
    </Box>
  );
}
