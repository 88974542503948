import { Button, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStore } from 'effector-react'
import React, { FormEvent, useState } from 'react'
import { types } from '../../../api'
import { sportIdList } from '../../../assets/lists'
import { closeModal } from '../../../stores/modal'
import { createRuleFx, updateRuleFx } from '../../../stores/rules'
import { $platforms, $providers, $zones } from '../../../stores/videos/store'
import { FormGroup } from './styled'

type Rule = types.VideoRuleResponse
export interface RuleForm {
  data?: Rule
}

const initialState: Omit<Rule, 'source'> & {
  source: string
} = {
  source: '',
  sportIds: [],
  champIds: [],
  addedZones: [],
  addedPlatform: [],
  deletedZones: [],
  deletedPlatform: [],
}
export function RuleForm({ data }: RuleForm) {
  const [values, setValues] = useState({ ...initialState, ...(data || {}) })
  const providers = useStore($providers) as string[]
  const zones = useStore($zones) as string[]
  const platforms = useStore($platforms) as string[]
  const isUpdateLoading = useStore(updateRuleFx.pending)
  const isCreateLoading = useStore(createRuleFx.pending)
  const isLoading = isUpdateLoading || isCreateLoading
  const isFormDisabled = !values.source

  const sportList = Object.keys(sportIdList).map((key) => ({
    id: key,
    name: sportIdList[key],
  }))

  const handleAdd = (value: string[] | number[] | string, key: keyof typeof values) => {
    setValues((s) => ({ ...s, [key]: value }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isUpdate = Boolean(data)
    if (isUpdate) {
      const id = String(data?.id || '')
      updateRuleFx({ ...(values as Rule), id }).then(() => closeModal())
    } else {
      createRuleFx(values as Rule).then(() => closeModal())
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Autocomplete
          filterSelectedOptions
          id="provider"
          value={values.source}
          options={providers.filter((prov) => prov !== 'IFRAME')}
          getOptionLabel={(option) => option}
          onChange={(_, value) => handleAdd(value || '', 'source')}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Провайдер" placeholder="Провайдер" />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          multiple
          id="sportsId"
          options={sportList.filter((sport) => !values.sportIds.includes(Number(sport.id)))}
          getOptionLabel={(option) => option.name}
          onChange={(_, value) =>
            handleAdd(
              value.map(({ id }) => Number(id)),
              'sportIds'
            )
          }
          defaultValue={sportList.filter((sport) => values.sportIds.includes(Number(sport.id)))}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Вид спорта" placeholder="Вид спорта" />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          filterSelectedOptions
          multiple
          freeSolo
          id="champIds"
          options={[]}
          defaultValue={values.champIds}
          disableClearable
          onChange={(_, value) => handleAdd(value as string[], 'champIds')}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              variant="outlined"
              label="Фильтр чемпионатов"
              placeholder="Введите id чемпионата"
              fullWidth
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          multiple
          filterSelectedOptions
          id="addedZones"
          options={zones}
          getOptionLabel={(option) => option}
          defaultValue={values.addedZones}
          onChange={(_, value) => handleAdd(value, 'addedZones')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Зоны запрета к добавлению"
              placeholder="Зоны запрета к добавлению"
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          multiple
          filterSelectedOptions
          id="addedPlatform"
          options={platforms}
          getOptionLabel={(option) => option}
          defaultValue={values.addedPlatform}
          onChange={(_, value) => handleAdd(value, 'addedPlatform')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Платформы к добавлению"
              placeholder="Платформы к добавлению"
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          multiple
          filterSelectedOptions
          id="deletedZones"
          options={zones}
          getOptionLabel={(option) => option}
          defaultValue={values.deletedZones}
          onChange={(_, value) => handleAdd(value, 'deletedZones')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Зоны запрета к исключению"
              placeholder="Зоны запрета к исключению"
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Autocomplete
          filterSelectedOptions
          multiple
          id="deletedPlatform"
          options={platforms}
          getOptionLabel={(option) => option}
          defaultValue={values.deletedPlatform}
          onChange={(_, value) => handleAdd(value, 'deletedPlatform')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Платформы к исключению"
              placeholder="Платформы к исключению"
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Button color="secondary" variant="contained" fullWidth type="submit" disabled={isLoading || isFormDisabled}>
          {isLoading ? 'Идет загрузка' : Boolean(data) ? 'Обновить' : 'Сохранить'}
        </Button>
      </FormGroup>
    </form>
  )
}
