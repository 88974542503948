/** @format */

import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextareaAutosize,
  TextField,
  Grid,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { styles } from "../../app/theme";
import {
  $customVideoDialog,
  $formIsValid,
  $formValues,
  addCustomVideo,
  onChange,
  resetCustomVideoDialog,
  resetForm,
  updateCustomVideo,
} from "../../stores/videos/custom-video";
import { $platforms, $zones } from "../../stores/videos/store";
import { Autocomplete } from "@material-ui/lab";
import { sportIdList } from "../../assets/lists";

const fields = [
  {
    label: "ID",
    value: "streamId",
    type: "number",
    onlyCreate: true,
  },
  {
    label: "Наименование",
    value: "name",
  },
  {
    label: "Вид спорта",
    value: "sportId",
    type: "number",
  },
  {
    label: "Наим. спорта",
    value: "sportName",
    type: "autocomplete",
    options: Object.keys(sportIdList).map((id) => ({
      id,
      title: sportIdList[id],
    })),
  },
];

const CustomVideoDialog = () => {
  const { isOpened, post } = useStore($customVideoDialog);
  const values = useStore($formValues);
  const formIsValid = useStore($formIsValid);
  const zones = useStore($zones);
  const platforms = useStore($platforms);
  const classes = styles();

  useEffect(() => {
    if (post) {
      onChange({
        streamId: post.streamId,
        sportId: post.sportId[0],
        sportName: post.sportName,
        name: post.name,
        code: post.code,
        startTime: post.startTime,
      });
    }
  }, [post]);

  useEffect(() => () => resetForm(), []);

  const onSubmit = () => {
    if (post) {
      const params = { ...values };
      delete params.removeForZone;
      delete params.allowedPlatforms;
      updateCustomVideo(params);
    } else addCustomVideo(values);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{ elevation: 4 }}
        open={isOpened}
        onClose={() => resetCustomVideoDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Добавить трансляцию</DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {fields.map((field) => {
              if (field.onlyCreate && post) return null;

              if (field.type === "autocomplete") {
                return (
                  <Grid item xs={3} key={field.value}>
                    <Autocomplete
                      inputValue={values[field.value]}
                      onChange={(_, option) =>
                        onChange({
                          [field.value]: option?.title || "",
                        })
                      }
                      onInputChange={(e) =>
                        e &&
                        onChange({
                          [field.value]: e.currentTarget.value,
                        })
                      }
                      options={field.options}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label={field.label}
                            type={"text"}
                            className={classes.input}
                          />
                        );
                      }}
                    />
                  </Grid>
                );
              }

              return (
                <Grid item xs={3} key={field.value}>
                  <TextField
                    fullWidth
                    label={field.label}
                    type={field.type || "text"}
                    value={values[field.value]}
                    onChange={(e) =>
                      onChange({ [field.value]: e.target.value })
                    }
                    className={classes.input}
                  />
                </Grid>
              );
            })}
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              ampm={false}
              format="dd.MM.yy HH:mm"
              disableToolbar
              autoOk
              label="Время начала"
              value={values.startTime}
              className={classes.input}
              onChange={(date) => {
                onChange({ startTime: new Date(date).getTime() });
              }}
            />
          </MuiPickersUtilsProvider>
          <TextareaAutosize
            placeholder="Код"
            className={classes.codeTextArea}
            value={values.code}
            onChange={(e) => onChange({ code: e.target.value })}
          />
          <br />
          {!post && (
            <>
              <div className={classes.customVideoSectionTitle}>
                Доступно для платформ:
              </div>
              <div
                className={classes.customVideoSection}
                style={{ height: 110 }}
              >
                {platforms.map((name) => (
                  <FormControlLabel
                    label={name}
                    key={name}
                    control={
                      <Checkbox
                        checked={values.allowedPlatforms.includes(name)}
                        size="small"
                        onChange={(event) => {
                          if (values.allowedPlatforms.includes(name))
                            onChange({
                              allowedPlatforms: values.allowedPlatforms.filter(
                                (platform) => platform !== name
                              ),
                            });
                          else
                            onChange({
                              allowedPlatforms: [
                                ...values.allowedPlatforms,
                                name,
                              ],
                            });
                        }}
                      />
                    }
                  />
                ))}
              </div>
              <div className={classes.customVideoSectionTitle}>
                Удалить для зон:
              </div>
              <div
                className={classes.customVideoSection}
                style={{ height: 150 }}
              >
                {zones.map((name) => (
                  <FormControlLabel
                    label={name}
                    key={name}
                    control={
                      <Checkbox
                        checked={values.removeForZone.includes(name)}
                        size="small"
                        onChange={(event) => {
                          if (values.removeForZone.includes(name))
                            onChange({
                              removeForZone: values.removeForZone.filter(
                                (zone) => zone !== name
                              ),
                            });
                          else
                            onChange({
                              removeForZone: [...values.removeForZone, name],
                            });
                        }}
                      />
                    }
                  />
                ))}
              </div>
            </>
          )}
          <Box direction="row" margin={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onSubmit()}
              disabled={!formIsValid}
              style={{ marginRight: 10 }}
            >
              Сохранить
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => resetCustomVideoDialog()}
            >
              Закрыть
            </Button>
          </Box>
        </DialogContent>

        <DialogActions disableSpacing></DialogActions>
      </Dialog>
    </div>
  );
};
export default CustomVideoDialog;
