export const sportIdList = {
  "1": "Футбол",
  "2": "Хоккей",
  "3": "Теннис",
  "4": "Снукер",
  "5": "Баскетбол",
  "6": "Американский футбол",
  "7": "Биатлон",
  "8": "Хоккей с мячом",
  "9": "Гандбол",
  "10": "Волейбол",
  "11": "Футзал",
  "12": "Бокс",
  "13": "Олимпийские игры",
  "29": "Бейсбол",
  "30": "Шахматы",
  "32": "Регби",
  "33": "Формула 1",
  "35": "Пляжный футбол",
  "37": "Керлинг",
  "38": "Лыжи",
  "39": "Прыжки с трамплина",
  "40": "Настольный теннис",
  "41": "Водное поло",
  "44": "Велоспорт",
  "45": "Пляжный гандбол",
  "46": "Регби-Союз",
  "47": "Дартс",
  "48": "Мотогонки",
  "49": "Скачки",
  "50": "Олимпиада-2008",
  "51": "Бадминтон",
  "52": "Дзюдо",
  "53": "Легкая атлетика",
  "54": "Парусный спорт",
  "55": "Пулевая стрельба",
  "56": "Стрельба из лука",
  "57": "Стрельба из пистолета",
  "58": "Гребля академическая",
  "59": "Плавание",
  "60": "Пляжный волейбол",
  "61": "Прыжки в воду",
  "62": "Софтбол",
  "63": "Спортивная гимнастика",
  "64": "Стендовая стрельба",
  "65": "Тяжелая атлетика",
  "66": "Греко-римская борьба",
  "67": "Хоккей на траве",
  "68": "Гребля на байдарках и каноэ",
  "69": "Вольная борьба",
  "70": "Синхронное плавание",
  "72": "Лакросс",
  "73": "Крикет",
  "74": "Бобслей",
  "75": "Горные лыжи",
  "76": "Конькобежный спорт",
  "77": "Лыжное двоеборье",
  "78": "Прыжки с трамплина",
  "79": "Сани",
  "80": "Скелетон",
  "81": "Сноубординг",
  "82": "Фигурное катание",
  "83": "Фристайл",
  "84": "Шорт-трек",
  "85": "Регби-лига",
  "88": "Политика",
  "89": "Минифутбол",
  "90": "Гольф",
  "91": "Австралийский футбол",
  "93": "Шары",
  "94": "Итоги",
  "95": "Виртуальный футбол",
  "96": "MMA",
  "98": "Ставки на лотереи",
  "100": "Виртуальные Скачки",
  "101": "Регби-7",
  "102": "Виртуальные велогонки",
  "103": "Виртуальные собачьи бега",
  "104": "Виртуальные скачки",
  "105": "Виртуальные автогонки",
  "106": "Виртуальный спидвэй",
  "107": "Виртуальный теннис",
  "108": "Виртуальные Собачьи Бега",
  "109": "Кено",
  "110": "Скачки",
  "111": "Рыбная ловля",
  "112": "Кибер спорт",
  "113": "Флорбол",
  "114": "Лыжное двоеборье",
  "115": "Песапалло",
  "116": "Спидвей",
  "117": "Авто/мотоспорт",
  "118": "DTM",
  "119": "Ралли",
  "120": "NASCAR",
  "121": "Культура",
  "122": "Виртуальный бокс",
  "123": "Сквош",
  "124": "Виртуальный баскетбол",
  "125": "Универсиада",
  "126": "Пул",
  "127": "Нетбол",
  "128": "Боулинг",
  "129": "Кабадди",
  "130": "Конный спорт",
  "131": "Сепактакрау",
  "132": "Скейтбординг",
  "133": "Современное пятиборье",
  "134": "Триатлон",
  "135": "Шорт-хоккей",
  "136": "Баскетбол 3х3",
  "137": "Гэльский футбол",
  "138": "Кибер футбол",
  "139": "Кибер теннис",
  "140": "Кибер баскетбол",
  "141": "Кибер хоккей",
  "142": "Борьба",
  "143": "Кулачные бои",
  "145": "Настольный футбол",
  "146": "Байга",
} as Record<string, string>;
