import React from "react";
import { Button } from "@material-ui/core";
import { openModal } from "../../../stores/modal";
import { RuleForm } from "../rule-form";

export function RuleCreateButton() {
  const handleOpenCreateRuleForm = () => {
    openModal(<RuleForm />, { title: "Создание нового правила" });
  };
  return (
    <Button
      onClick={handleOpenCreateRuleForm}
      variant="contained"
      color="secondary"
    >
      Добавить правило
    </Button>
  );
}
