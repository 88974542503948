import React from "react";
import { types } from "../../../api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { format } from "date-fns";
import {
  ChangeBefore,
  ChangeAfter,
  Changes,
  ChangeIcon,
  ChangeLabel,
} from "./styled";
import * as Icons from "@material-ui/icons";

export interface UserChangelogTable {
  logs: types.UserHistoricalChange[];
}

export function UserChangelogTable({ logs }: UserChangelogTable) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 160 }}>Автор</TableCell>
            <TableCell>Изменения</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow key={log.dateTime}>
              <TableCell style={{ verticalAlign: "top" }}>
                <strong>{log.authorOfChanging}</strong>
                <div>
                  {format(new Date(String(log.dateTime)), "dd.MM.yyyy, hh:mm")}
                </div>
              </TableCell>
              <TableCell style={{ verticalAlign: "top" }}>
                {log.historicalChange.map((change, index) => (
                  <Changes key={index}>
                    <ChangeLabel>{change.propertyName}</ChangeLabel>
                    <div>
                      <ChangeBefore>
                        <ChangeIcon>
                          <Icons.Remove fontSize="small" />
                        </ChangeIcon>
                        {String(
                          change.propertyName === "password"
                            ? "************"
                            : change.oldValue
                        )}
                      </ChangeBefore>
                      <ChangeAfter>
                        <ChangeIcon>
                          <Icons.Add fontSize="small" />
                        </ChangeIcon>
                        {String(
                          change.propertyName === "password"
                            ? "************"
                            : change.newValue
                        )}
                      </ChangeAfter>
                    </div>
                  </Changes>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
