import { createEffect, createStore } from "effector";
import { types, adminApi } from "../api";

// Effects
export const getUserFx = createEffect({
  handler: adminApi.getCurrentUser,
});

// Stores
export const $user = createStore<types.UserResponse | null>(null);

// Logics
$user.on(getUserFx.doneData, (_, payload) => payload.data);
