import React from "react";
import { Button } from "@material-ui/core";
import { openModal } from "../../../stores/modal";
import { UserForm } from "../user-form";

export function UserCreateButton() {
  const handleOpenCreateUserForm = () => {
    openModal(<UserForm />, { title: "Создание нового пользователя" });
  };
  return (
    <Button
      onClick={handleOpenCreateUserForm}
      variant="contained"
      color="secondary"
    >
      Добавить пользователя
    </Button>
  );
}
