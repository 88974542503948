import { createEvent, merge, sample, createStore } from "effector";
import {
  reset,
  update as updateBindMatchesFx,
  updatePrioritiesFx,
} from "./store";
import {
  customVideoAction,
  customVideoDone,
  customVideoFail,
} from "./custom-video";

export const resetRequestModal = createEvent();
const setRequestModalStatus = createEvent();

const onAction = merge([
  updateBindMatchesFx.pending,
  updatePrioritiesFx.pending,
  customVideoAction,
  reset.pending,
]);
const onActionFail = merge([
  updateBindMatchesFx.doneData,
  updatePrioritiesFx.doneData,
  customVideoDone,
]);

sample({
  source: onAction,
  fn: (isPending) => ({ isPending }),
  target: setRequestModalStatus,
});

sample({
  source: onActionFail,
  fn: (res) => ({ errorMessage: res?.code !== 200 ? res.desc : null }),
  target: setRequestModalStatus,
});

sample({
  source: customVideoFail,
  fn: (res) => ({ errorMessage: res?.error?.response?.data?.desc }),
  target: setRequestModalStatus,
});

export const $requestModal = createStore({
  isPending: false,
  errorMessage: "",
})
  .on(setRequestModalStatus, (state, payload) => ({ ...state, ...payload }))
  .reset(resetRequestModal);
