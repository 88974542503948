import { createEffect, createStore, forward } from "effector";
import { adminApi, types } from "../api";

// Effects
export const getAllRulesFx = createEffect({ handler: adminApi.getAllRules });
export const createRuleFx = createEffect({ handler: adminApi.createRule });
export const deleteRuleFx = createEffect({ handler: adminApi.deleteRule });
export const updateRuleFx = createEffect({
  handler: ({
    id,
    ...payload
  }: Omit<types.VideoRuleResponse, "id"> & { id: string }) =>
    adminApi.updateRule(id, payload),
});

// Stores
export const $allRules = createStore<types.VideoRuleResponse[]>([]);

// Logics
$allRules
  .on(getAllRulesFx.doneData, (_, { data }) => data)
  .on(deleteRuleFx.done, (state, { params: id }) =>
    state.filter((rule) => rule.id !== id)
  );

forward({
  from: [createRuleFx.doneData, updateRuleFx.doneData],
  to: getAllRulesFx,
});
