export const pathsWithRules = {
  main: {
    rules: [],
    url: () => "/",
  },
  users: {
    rules: [],
    url: () => "/users",
  },
  rules: {
    rules: [],
    url: () => "/rules",
  },
};
