/** @format */

import React from "react";
import { useStore } from "effector-react";
import { styles } from "../../app/theme";
import * as Zone from "../../stores/videos/zone";
import * as Store from "../../stores/videos/store";
import * as Utils from "./utils";
import {
  Backdrop,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { $zones } from "../../stores/videos/store";

const ZonePopper = () => {
  const classes = styles();
  const anchorEl = useStore(Zone.$anchorEl);
  const post = useStore(Zone.$post);
  const index = useStore(Zone.$index);
  const zones = useStore($zones);

  return (
    <Backdrop
      invisible
      style={{ zIndex: 0 }}
      open={Boolean(anchorEl)}
      onClick={Utils.closeZone}
    >
      <Popper
        onClick={(event) => event.stopPropagation()}
        style={{ zIndex: 1 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper elevation={3} style={{ overflowY: "auto", padding: 10 }}>
          <List dense disablePadding>
            {zones.filter((platform) => !post.removeForZone.includes(platform))
              .length
              ? zones
                  .filter((platform) => !post.removeForZone.includes(platform))
                  .map((platform) => (
                    <ListItem
                      disableGutters
                      onClick={() => {
                        let temp = { ...post };
                        temp.removeForZone = [...temp.removeForZone, platform];

                        Zone.setPost(temp);

                        Store.update({
                          id: post.id,
                          index: index,
                          key: "removeForZone",
                          value: temp.removeForZone,
                        });
                      }}
                      button
                      key={platform}
                      className={classes.noPadding}
                    >
                      <ListItemText
                        className={classes.noMargin}
                        primary={`${platform}`}
                      />
                    </ListItem>
                  ))
              : "Все доступные запреты уже добавлены!"}
          </List>
        </Paper>
      </Popper>
    </Backdrop>
  );
};

export default ZonePopper;
