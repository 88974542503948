import { styled } from "@material-ui/core";

export const Changes = styled("div")({
  "& + &": {
    marginTop: 8,
  },
});

const Change = styled("div")({
  padding: 4,
  whiteSpace: "nowrap",
  position: "relative",
  paddingLeft: 36,
});

export const ChangeBefore = styled(Change)(({ theme }) => ({
  background: theme.palette.error.light,
}));

export const ChangeAfter = styled(Change)(({ theme }) => ({
  background: theme.palette.success.light,
}));

export const ChangeLabel = styled("strong")({
  marginBottom: 8,
  display: "block",
});

export const ChangeIcon = styled("div")({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  width: 36,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
