import React from "react";
import { styles } from "../../app/theme";
import clsx from "clsx";

const Description = () => {
  const classes = styles();

  return (
    <div className={classes.description}>
      <div>
        <div className={classes.block} />
        <div>Не привязана</div>
      </div>
      <div>
        <div className={clsx(classes.matched, classes.block)} />
        <div>Создана автоматически</div>
      </div>
      <div>
        <div className={clsx(classes.edited, classes.block)} />
        <div>Создана автоматически, ред.</div>
      </div>
      <div>
        <div className={clsx(classes.custom, classes.block)} />
        <div>Создана вручную</div>
      </div>
      <div>
        <div className={clsx(classes.customEdited, classes.block)} />
        <div>Создана вручную, ред.</div>
      </div>
    </div>
  );
};

export default Description;
