import { DialogProps } from "@material-ui/core";
import { createEvent, restore } from "effector";
import { ReactElement } from "react";
export interface ModalData {
  element: ReactElement;
  options?: ModalOptions;
}

interface ModalOptions {
  title?: string;
  shouldHideCloseButton?: boolean;
  modalProps?: Omit<DialogProps, "open">;
}

const createModal = createEvent<ModalData>();
export const $modal = restore(createModal, null);
export const closeModal = createEvent();
export const openModal = (element: ReactElement, options?: ModalOptions) =>
  createModal({ element, options });
export const setModalLoader = createEvent<boolean>();

$modal.reset(closeModal);
