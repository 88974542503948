/** @format */

import React from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import * as EffectorReact from "effector-react";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  GridList,
  Chip,
  IconButton,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import * as Store from "../../stores/videos/store";
import * as Priority from "../../stores/videos/priority";
import PriorityPopper from "./priority-popper";
import { $zones, updatePriorities } from "../../stores/videos/store";

const SortableItem = sortableElement(
  ({ a: providers, k: zone, value: provider }) => (
    <Chip
      clickable
      size="small"
      label={`${provider}`}
      style={{ zIndex: 1300, marginBottom: 2 }}
      onDelete={() => {
        Store.updatePriorities({
          zone,
          providers: providers.filter((pr) => pr !== provider),
        });
      }}
    />
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
    >
      {children}
    </Grid>
  );
});

const PriorityDialog = () => {
  const priorityOpen = EffectorReact.useStore(Store.$priorityOpen);
  const priorities = EffectorReact.useStore(Store.$priorities);
  const isPending = EffectorReact.useStore(Store.getPriorities.pending);
  const zones = EffectorReact.useStore($zones);
  const priorityPopperAnchorEl = EffectorReact.useStore(Priority.$anchorEl);

  React.useEffect(() => {
    priorityOpen && Store.getPriorities();
  }, [priorityOpen]);

  if (Object.keys(priorities).length === 0 && !isPending) return null;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        //BackdropProps={{ invisible: true }}
        PaperProps={{ elevation: 4 }}
        open={priorityOpen}
        onClose={() => Store.setPriorityOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Настройка приоритетов</DialogTitle>

        <DialogContent>
          {isPending ? (
            <CircularProgress
              size={200}
              color="secondary"
              style={{ display: "flex", margin: "30px auto" }}
            />
          ) : (
            <>
              <GridList component="div" cols={4} cellHeight="auto">
                {Object.values(zones).map((zone) => {
                  const providers = priorities[zone] || [];
                  return (
                    <div key={zone}>
                      <h3>{`${zone}`}</h3>
                      <SortableContainer
                        pressDelay={200}
                        // hideSortableGhost={false}
                        onSortEnd={({ oldIndex, newIndex }) => {
                          if (oldIndex !== newIndex) {
                            const sortedProviders = arrayMove(
                              providers,
                              oldIndex,
                              newIndex
                            );
                            Store.setPriorities({
                              [zone]: sortedProviders,
                            });
                            updatePriorities({
                              zone,
                              providers: sortedProviders,
                            });
                          }
                        }}
                      >
                        {providers.map((v, i, a) => (
                          <SortableItem
                            key={`item-${v}`}
                            a={a}
                            k={zone}
                            index={i}
                            value={v}
                          />
                        ))}
                      </SortableContainer>
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          Priority.setPost(providers);
                          Priority.setIndex(zone);
                          Priority.setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MatIcons.AddCircle />
                      </IconButton>
                    </div>
                  );
                })}
              </GridList>
            </>
          )}
        </DialogContent>

        <DialogActions disableSpacing></DialogActions>
      </Dialog>
      {priorityPopperAnchorEl && <PriorityPopper />}
    </div>
  );
};
export default PriorityDialog;
