import {
  AppBar,
  Backdrop,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Popper,
  Toolbar,
} from "@material-ui/core";
import { useStore } from "effector-react";
import React, { useState } from "react";
import { styles } from "../../app/theme";
import * as Manual from "../../stores/videos/manual";
import * as Store from "../../stores/videos/store";
import * as Utils from "./utils";

const ManualPopper = () => {
  const classes = styles();

  const anchorEl = useStore(Manual.$anchorEl);
  const post = useStore(Manual.$post);
  const index = useStore(Manual.$index);

  const [olimpId, setOlimpIdId] = useState("");

  const updateOlimpIds = () => {
    const olimpIds = {
      ...post.olimpIds,
      ...{ [olimpId]: "Добавлен вручную @ Добавлен вручную @ " + Date.now() },
    };

    Store.update({
      id: post.id,
      index: index,
      key: "olimpIds",
      value: olimpIds,
    });
    Utils.closeManual();
  };

  return (
    <Backdrop
      invisible
      style={{ zIndex: 0 }}
      open={Boolean(anchorEl)}
      onClick={Utils.closeManual}
    >
      <Popper
        onClick={(event) => event.stopPropagation()}
        style={{ zIndex: 1 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper elevation={3} className={classes.paper}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel color="secondary">Olimp ID</InputLabel>
            <OutlinedInput
              type="number"
              labelWidth={55}
              color="secondary"
              defaultValue=""
              onChange={(event) => setOlimpIdId(event.target.value)}
            />
          </FormControl>
        </Paper>
        <AppBar
          // style={{ top: 'auto' }}
          classes={{ positionFixed: classes.appBar }}
          elevation={0}
          position="fixed"
          color="transparent"
        >
          <Toolbar className={classes.toolBar} variant="dense">
            <Button
              variant="contained"
              size="small"
              className={classes.ok}
              onClick={() => updateOlimpIds(olimpId)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="small"
              className={classes.cancel}
              onClick={Utils.closeManual}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
      </Popper>
    </Backdrop>
  );
};

export default ManualPopper;
