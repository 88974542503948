import {
  createEvent,
  createStore,
  forward,
  guard,
  merge,
  sample,
} from "effector";
import * as Effector from "effector";
import axios from "axios";
import { $auth, $fetchParams, fetchVideos } from "./store";
import { sportIdList } from "../../assets/lists";

// --- dialog state ---
export const openCustomVideoDialog = createEvent();
export const resetCustomVideoDialog = createEvent();
export const $customVideoDialog = createStore({
  isOpened: false,
  post: null,
})
  .on(openCustomVideoDialog, (_, post) => ({ isOpened: true, post }))
  .reset(resetCustomVideoDialog);

// --- custom video actions ---
export const addCustomVideo = Effector.createEffect({
  handler: async (videoData) => {
    const res = await axios({
      method: "post",
      url: "iframe",
      auth: $auth.getState(),
      data: videoData,
    });

    return res.data;
  },
});

export const deleteCustomVideo = Effector.createEffect({
  handler: async (streamId) => {
    const res = await axios({
      method: "delete",
      url: `iframe/${streamId}`,
      auth: $auth.getState(),
    });

    return res.data;
  },
});

export const updateCustomVideo = Effector.createEffect({
  handler: async (videoData) => {
    const res = await axios({
      method: "put",
      url: `iframe/${videoData.streamId}`,
      auth: $auth.getState(),
      data: videoData,
    });

    return res.data;
  },
});

export const customVideoAction = merge([
  addCustomVideo.pending,
  deleteCustomVideo.pending,
  updateCustomVideo.pending,
]);
export const customVideoDone = merge([
  addCustomVideo.doneData,
  deleteCustomVideo.doneData,
  updateCustomVideo.doneData,
]);
export const customVideoFail = merge([
  addCustomVideo.fail,
  deleteCustomVideo.fail,
  updateCustomVideo.fail,
]);

sample({
  clock: customVideoDone,
  source: $fetchParams,
  target: fetchVideos,
});

forward({
  from: customVideoDone,
  to: resetCustomVideoDialog,
});

// --- form state ---
export const onChange = createEvent();
const onChangeSafe = createEvent();
export const resetForm = createEvent();
export const $formValues = createStore({
  streamId: "",
  name: "",
  sportId: "",
  sportName: "",
  code: "",
  startTime: null,
  allowedPlatforms: [],
  removeForZone: [],
})
  .on([onChange, onChangeSafe], (state, payload) => ({ ...state, ...payload }))
  .reset(resetForm);
export const $formIsValid = $formValues.map((values) =>
  Object.values(values).every((x) => x)
);

//Выбор спорта старт
const setSportName = createEvent();
const setSportId = createEvent();

guard({
  source: onChange,
  filter: (payload) => payload.hasOwnProperty("sportId"),
  target: setSportName,
});

guard({
  source: onChange,
  filter: (payload) =>
    payload.hasOwnProperty("sportName") &&
    Object.values(sportIdList).includes(payload?.sportName),
  target: setSportId,
});

sample({
  source: setSportName,
  fn: (payload) => ({ sportName: sportIdList[payload.sportId] || "" }),
  target: onChangeSafe,
});

sample({
  source: setSportId,
  fn: (payload) => {
    const sportId =
      Object.keys(sportIdList)[
        Object.values(sportIdList).findIndex(
          (value) => value === payload?.sportName
        )
      ];
    return { sportId };
  },
  target: onChangeSafe,
});

//Выбор спорта конец
