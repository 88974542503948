import { styles } from "../../app/theme";
import {
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Button,
} from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import React from "react";
import { sportIdList } from "../../assets/lists";
import * as Platform from "../../stores/videos/platform";
import * as Zone from "../../stores/videos/zone";
import * as Search from "../../stores/videos/search";
import * as Manual from "../../stores/videos/manual";
import * as Store from "../../stores/videos/store";
import * as Utils from "./utils";
import VideoActions from "./video-actions";

const Videos = ({ post, index }) => {
  const classes = styles();

  const removeOlimpId = (key, index) => {
    let tempIds = post.olimpIds;

    delete tempIds[key];

    Store.update({
      id: post.id,
      index: index,
      key: "olimpIds",
      value: tempIds,
    });
  };

  const getClassName = () => {
    if (post.isCustomEntity && post.allowResetManualUpdates)
      return classes.customEdited;
    if (post.isCustomEntity) return classes.custom;
    if (post.allowResetManualUpdates) return classes.edited;
    if (Object.entries(post.olimpIds).length) return classes.matched;
    if (!(index % 2)) return classes.oddRow;
    return "";
  };

  return !post ? null : (
    <Accordion
      className={getClassName()}
      classes={{ root: classes.expPanel, expanded: classes.expanded }}
      key={post.id}
    >
      <AccordionSummary
        classes={{
          root: classes.expPanelSummary,
          content: classes.expPanelSummaryContent,
          expanded: classes.expanded,
        }}
        expandIcon={<MatIcons.ExpandMore />}
      >
        <Grid
          className={classes.defaultPointer}
          onClick={(event) => {
            event.stopPropagation();
          }}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={1} style={{ maxWidth: "2%" }}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs={4} container direction="row" justify="center">
                <Tooltip title="live" aria-label="live">
                  <Switch
                    size="small"
                    checked={post.live}
                    onChange={() =>
                      Store.update({
                        id: post.id,
                        index: index,
                        key: "live",
                        value: !post.live,
                      })
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} style={{ maxWidth: "4%" }}>
            <div className={classes.textCenter}>{post.streamId}</div>
          </Grid>

          <Grid item xs={1}>
            <div className={classes.textCenter}>{post.src}</div>
          </Grid>

          <Grid item xs={1}>
            <div className={classes.textCenter}>
              {Utils.bothFormater(post.startTime)}
            </div>
          </Grid>

          <Grid item xs={1}>
            <div className={classes.textCenter} style={{ paddingRight: 10 }}>
              {Array.isArray(post.sportId) &&
              post.sportId.length > 0 &&
              sportIdList[post.sportId[0]]
                ? sportIdList[post.sportId[0]]
                : `/* ${post.sportName} */`}
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className={classes.textCenter}>
              {post.champName ? post.champName + ". " + post.name : post.name}
            </div>
          </Grid>

          <Grid item xs={4}>
            <div style={{ minHeight: 25 }}>
              {Object.entries(post.linkedOlimpIds).map(([key, value]) => {
                let s = value.split("@");
                return (
                  <div key={key} className={classes.text}>
                    <IconButton disabled size="small">
                      <MatIcons.Link color="disabled" />
                    </IconButton>
                    {`${Utils.bothFormater(s[s.length - 1]) || ""} ${
                      s[0]
                    } [${key}]`}
                  </div>
                );
              })}

              {Object.entries(post.olimpIds).map(([key, value]) => {
                let s = value.split("@");
                return (
                  <div key={key} className={classes.text}>
                    <IconButton
                      size="small"
                      onClick={() => removeOlimpId(key, index)}
                    >
                      <MatIcons.Clear color="error" />
                    </IconButton>
                    {`${Utils.bothFormater(s[s.length - 1]) || ""} ${
                      s[0]
                    } [${key}]`}
                  </div>
                );
              })}
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={5}>
                <Button
                  style={{ textTransform: "none", fontWeight: 400 }}
                  fullWidth
                  color="primary"
                  size="small"
                  startIcon={<MatIcons.Add />}
                  className={classes.noPadding}
                  onClick={(event) => {
                    Search.setPost(post);
                    Search.setIndex(index);
                    Search.setAnchorEl(event.currentTarget);
                  }}
                >
                  Привязать матч
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  style={{ textTransform: "none", fontWeight: 400 }}
                  fullWidth
                  size="small"
                  startIcon={<MatIcons.Attachment />}
                  className={classes.noPadding}
                  onClick={(event) => {
                    Manual.setPost(post);
                    Manual.setIndex(index);
                    Manual.setAnchorEl(event.currentTarget);
                  }}
                >
                  Ручная привязка
                </Button>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <VideoActions post={post} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              spacing={1}
              container
              direction="row"
              justify="flex-start"
              alignItems="baseline"
            >
              {post.allowedPlatforms.sort().map((platform, i) => (
                <Grid item key={platform}>
                  <Chip
                    clickable
                    size="small"
                    label={`${platform}`}
                    onDelete={() => {
                      let tempPlatforms = post.allowedPlatforms;

                      tempPlatforms.splice(i, 1);

                      Store.update({
                        id: post.id,
                        index: index,
                        key: "allowedPlatforms",
                        value: tempPlatforms,
                      });
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    Platform.setPost(post);
                    Platform.setIndex(index);
                    Platform.setAnchorEl(event.currentTarget);
                  }}
                >
                  <MatIcons.AddCircle />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              spacing={1}
              container
              direction="row"
              justify="flex-start"
              alignItems="baseline"
            >
              {post.removeForZone.sort().map((zone, i) => (
                <Grid item key={zone}>
                  <Chip
                    clickable
                    size="small"
                    label={`${zone}`}
                    onDelete={() => {
                      let tempZone = post.removeForZone;

                      tempZone.splice(i, 1);

                      Store.update({
                        id: post.id,
                        index: index,
                        key: "removeForZone",
                        value: tempZone,
                      });
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <IconButton
                  size="small"
                  onClick={(event) => {
                    Zone.setPost(post);
                    Zone.setIndex(index);
                    Zone.setAnchorEl(event.currentTarget);
                  }}
                >
                  <MatIcons.AddCircle />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default Videos;
