/** @format */
import { Accordion, AccordionSummary, Button, Grid } from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import { useStore } from "effector-react";
import React from "react";
import { styles } from "../../app/theme";
import * as Store from "../../stores/videos/store";

const HeaderRow = () => {
  const classes = styles();

  const fetchParams = useStore(Store.$fetchParams);

  const setsort = (event, prop) => {
    event.stopPropagation();

    fetchParams.sort.hasOwnProperty(prop)
      ? fetchParams.sort[prop] === true
        ? Store.setFetchParams({ sort: { [prop]: false } })
        : Store.setFetchParams({ sort: {} })
      : Store.setFetchParams({ sort: { [prop]: true } });
  };

  return (
    <Accordion classes={{ root: classes.expPanel, expanded: classes.expanded }}>
      <AccordionSummary
        style={{ padding: "0 50px 0 5px" }}
        classes={{
          root: classes.expPanelSummary,
          content: classes.expPanelSummaryContent,
          expanded: classes.expanded,
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={1} style={{ maxWidth: "2.5%" }}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="small"
                  endIcon={
                    fetchParams.sort.hasOwnProperty("live") ? (
                      fetchParams.sort.live === true ? (
                        <MatIcons.ArrowDropDown />
                      ) : (
                        <MatIcons.ArrowDropUp />
                      )
                    ) : null
                  }
                  onClick={(event) => setsort(event, "live")}
                >
                  Live
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} style={{ maxWidth: "4%" }}>
            <Button
              fullWidth
              size="small"
              endIcon={
                fetchParams.sort.hasOwnProperty("streamId") ? (
                  fetchParams.sort.streamId === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => {
                setsort(event, "streamId");
              }}
            >
              ID
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              endIcon={
                fetchParams.sort.hasOwnProperty("src") ? (
                  fetchParams.sort.src === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => setsort(event, "src")}
            >
              Поставщик
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              endIcon={
                fetchParams.sort.hasOwnProperty("startTime") ? (
                  fetchParams.sort.startTime === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => {
                setsort(event, "startTime");
              }}
            >
              Дата/Время
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Button
              fullWidth
              size="small"
              endIcon={
                fetchParams.sort.hasOwnProperty("sportId") ? (
                  fetchParams.sort.sportId === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => setsort(event, "sportId")}
            >
              Вид спорта
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button
              size="small"
              fullWidth
              endIcon={
                fetchParams.sort.hasOwnProperty("name") ? (
                  fetchParams.sort.name === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => {
                setsort(event, "name");
              }}
            >
              Трансляция
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="small"
              endIcon={
                fetchParams.sort.hasOwnProperty("olimpIds") ? (
                  fetchParams.sort.olimpIds === true ? (
                    <MatIcons.ArrowDropDown />
                  ) : (
                    <MatIcons.ArrowDropUp />
                  )
                ) : null
              }
              onClick={(event) => setsort(event, "olimpIds")}
            >
              Привязан к матчу (ID)
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};

export default HeaderRow;
