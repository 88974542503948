import React from "react";
import * as EffectorReact from "effector-react";
import {
  CircularProgress,
  Modal,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import {
  $requestModal,
  resetRequestModal,
} from "../../stores/videos/request-modal";

export const RequestModal = () => {
  const { isPending, errorMessage } = EffectorReact.useStore($requestModal);

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isPending ? "max-content" : 400,
    background: isPending ? "transparent" : "white",
    outline: "none",
    padding: 20,
  };

  return (
    <div>
      <Modal
        open={isPending || Boolean(errorMessage)}
        onClose={() => {
          if (!isPending) resetRequestModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styles}>
          {isPending && <CircularProgress size={200} color="secondary" />}
          {errorMessage && (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Произошла ошибка
              </Typography>
              <Typography
                id="modal-modal-description"
                style={{ margin: "10px 0" }}
              >
                {errorMessage}
              </Typography>
              <Button
                onClick={() => resetRequestModal()}
                variant="contained"
                style={{ marginTop: 10 }}
              >
                Закрыть
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
