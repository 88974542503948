import { createEffect, createEvent, createStore, restore } from "effector";
import axios from "axios";
import { $auth } from "./store";

// --- dialog state ---
export const toggleReportDialog = createEvent();
export const $reportDialog = createStore(false).on(
  toggleReportDialog,
  (isOpened) => !isOpened
);

export const downloadReportFx = createEffect({
  handler: async (params) => {
    return axios({
      method: "get",
      url: "report",
      auth: $auth.getState(),
      params,
    });
  },
});

export const $error = restore(
  downloadReportFx.failData.map((e) => e.message),
  ""
);

downloadReportFx.done.watch(({ result }) => {
  if (result.status !== 200) return;

  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${result.data}`);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);

  const fileName = result.headers["content-disposition"].split("filename=")[1];
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});

// --- form state ---
export const onChange = createEvent();
export const resetForm = createEvent();
export const $formValues = createStore({
  providers: [],
  from: null,
  to: null,
})
  .on(onChange, (state, payload) => ({ ...state, ...payload }))
  .reset(resetForm);

export const $isFormValid = $formValues.map((values) =>
  Object.values(values).every((x) =>
    Array.isArray(x) ? x.length > 0 : Boolean(x)
  )
);
