import * as Effector from 'effector'

export const $anchorEl = Effector.createStore(null)
export const setAnchorEl = Effector.createEvent()
export const resetAnchorEl = Effector.createEvent()

$anchorEl.on(setAnchorEl, (_state, data) => data).reset(resetAnchorEl)

export const $post = Effector.createStore([])
export const setPost = Effector.createEvent()
export const resetPost = Effector.createEvent()

$post.on(setPost, (_state, data) => data).reset(resetPost)

export const $index = Effector.createStore(null)
export const setIndex = Effector.createEvent()
export const resetIndex = Effector.createEvent()

$index.on(setIndex, (_state, data) => data).reset(resetIndex)
