import { createTheme, makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles((theme) => ({
  noMargin: {
    margin: "0 !important",
  },

  noPadding: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    width: "max-content",
  },

  actions: {
    display: "flex",
    marginBottom: 5,
    "& svg": {
      width: 20,
      height: 20,
      cursor: "pointer",
      marginRight: 4,
    },
  },

  ok: {
    pointerEvents: "auto",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  cancel: {
    pointerEvents: "auto",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paper: {
    paddingBottom: 48,
    maxHeight: "50vh",
    overflowY: "auto",
    padding: 10,
  },

  appBar: {
    top: "auto !important",
    bottom: 0,
    pointerEvents: "none",
  },

  toolBar: {
    justifyContent: "space-evenly",
  },

  live: {
    fontFamily: "Roboto Mono, monospace",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    border: 2,
    borderRadius: 4,
    borderStyle: "solid",
    padding: "0px 4px",
  },

  line: {
    fontFamily: "Roboto Mono, monospace",
    fontWeight: "bold",
    color: theme.palette.text.disabled,
    padding: "0px 6px",
  },

  text: { width: "fit-content", cursor: "text", userSelect: "text" },

  textCenter: {
    textAlign: "center",
    width: "fit-content",
    margin: "auto",
    cursor: "text",
    userSelect: "text",
  },

  defaultPointer: {
    cursor: "default",
  },

  codeTextArea: {
    width: "100%",
    maxWidth: "100%",
    minHeight: 120,
    marginTop: 20,
  },

  description: {
    display: "flex",
    margin: "20px 0",
    "& > div": {
      display: "flex",
      alignItems: "center",
      marginRight: 15,
    },
  },

  block: {
    width: 40,
    height: 20,
    marginRight: 5,
    borderRadius: 5,
    border: "1px solid #c5c4c4",
  },

  matched: {
    background: theme.palette.success.light + "90",
  },

  edited: {
    background: theme.palette.warning.light + "90",
  },

  custom: {
    background: "#a9cce1",
  },

  customEdited: {
    background: "#d3b8f3",
  },

  oddRow: {
    filter: "brightness(85%)",
  },

  empty: {
    margin: "50px 0",
  },

  input: {
    margin: 10,
  },

  deleteConfirmPopper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    padding: 7,
    borderRadius: 5,
    border: "1px solid gray",
    fontSize: 14,
    "& button": {
      padding: 0,
      marginTop: 10,
    },
  },

  expPanel: {
    margin: "0 !important",
    "&$expanded": {
      margin: "0 !important",
    },
  },
  expPanelSummary: {
    margin: "0 !important",
    "&$expanded": {
      margin: "0 !important",
      minHeight: "48px !important",
    },
  },
  expPanelSummaryContent: {
    margin: "0 !important",
    "&$expanded": {
      margin: "0 !important",
    },
  },
  expanded: {},
  iframe: {
    width: 870,
    height: 500,
    marginBottom: 30,
    borderRadius: 8,
    border: "none",
  },

  customVideoSection: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  customVideoSectionTitle: {
    fontWeight: 500,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 7,
  },
}));

export const myLight = createTheme({
  typography: {
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#424242",
      contrastText: "#eeeeee",
    },

    secondary: {
      main: "#26a69a",
      contrastText: "#e0f2f1",
    },
  },
});

export const dateLight = createTheme({
  typography: {
    fontSize: 13,
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: "#26a69a",
      },
    },
  },
  palette: {
    primary: {
      main: "#26a69a",
      contrastText: "#e0f2f1",
    },

    secondary: {
      main: "#26a69a",
      contrastText: "#e0f2f1",
    },
  },
});

//https://react-theming.github.io/create-mui-theme/
