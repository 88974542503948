import React from "react";
import { useEffect } from "react";
import { $allRules, getAllRulesFx } from "../../../stores/rules";
import { useStore } from "effector-react";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { Loading, TableHead, TableBody, Chips } from "./styled";
import { openModal } from "../../../stores/modal";
import { deleteRuleFx } from "../../../stores/rules";
import { types } from "../../../api";
import { sportIdList } from "../../../assets/lists";
import { RuleForm } from "../rule-form";

type Rule = types.VideoRuleResponse;
export function RulesTable() {
  const allRules = useStore($allRules);
  const isLoading = useStore(getAllRulesFx.pending);
  const isEmpty = allRules.length === 0 && !isLoading;
  const isDeleteLoading = useStore(deleteRuleFx.pending);
  const actionButtonDisabled = isDeleteLoading;

  useEffect(() => {
    void getAllRulesFx();
  }, []);

  const handleOpenRuleEditForm = (rule: Rule) => {
    openModal(<RuleForm data={rule} />, {
      title: `Редактирование правила с id ${rule.id}`,
    });
  };
  const handleDeleteRule = (rule: Rule) => {
    const isConfirm = confirm(
      `Вы уверены, что хотите удалить правило с id ${rule.id}?`
    );
    if (isConfirm) deleteRuleFx(rule.id as string);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Поставщик</TableCell>
            <TableCell align="center">Фильтр видов спорта</TableCell>
            <TableCell align="center">Фильтр чемпионатов</TableCell>
            <TableCell align="center">Зоны запрета к добавлению</TableCell>
            <TableCell align="center">Платформы к добавлению</TableCell>
            <TableCell align="center">Зоны запрета к исключению</TableCell>
            <TableCell align="center">Платформы к исключению</TableCell>
            <TableCell align="center">Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading || isEmpty ? (
            <TableRow>
              <TableCell colSpan={8}>
                {isEmpty && (
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    align="center"
                  >
                    Данные отсутсвуют
                  </Typography>
                )}
                {isLoading && (
                  <Loading>
                    <CircularProgress color="secondary" />
                  </Loading>
                )}
              </TableCell>
            </TableRow>
          ) : (
            <>
              {allRules.map((rule) => (
                <TableRow key={rule.id as string}>
                  <TableCell align="center" className="source">
                    {rule.source}
                  </TableCell>
                  <TableCell align="center" className="sportIds">
                    <Chips>
                      {rule.sportIds.map((id) => {
                        if (!sportIdList[String(id)]) return null;
                        return (
                          <Chip
                            size="small"
                            key={id}
                            label={`${sportIdList[String(id)]} (${id})`}
                          />
                        );
                      })}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="champIds">
                    <Chips>
                      {rule.champIds.map((id) => (
                        <Chip size="small" key={id} label={id} />
                      ))}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="addedZones">
                    <Chips>
                      {rule.addedZones.map((id) => (
                        <Chip size="small" key={id} label={id} />
                      ))}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="addedPlatform">
                    <Chips>
                      {rule.addedPlatform.map((id) => (
                        <Chip size="small" key={id} label={id} />
                      ))}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="deletedZones">
                    <Chips>
                      {rule.deletedZones.map((id) => (
                        <Chip size="small" key={id} label={id} />
                      ))}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="deletedPlatform">
                    <Chips>
                      {rule.deletedPlatform.map((id) => (
                        <Chip size="small" key={id} label={id} />
                      ))}
                    </Chips>
                  </TableCell>
                  <TableCell align="center" className="actions">
                    <Tooltip title="Изменить данные">
                      <IconButton
                        disabled={actionButtonDisabled}
                        onClick={() => handleOpenRuleEditForm(rule)}
                      >
                        <Icons.Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить данные">
                      <IconButton
                        disabled={actionButtonDisabled}
                        onClick={() => handleDeleteRule(rule)}
                      >
                        <Icons.Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
