/** @format */
import React from "react";
import { useStore } from "effector-react";
import { AppBar, Toolbar, IconButton, Button, styled } from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import * as Store from "../../stores/videos/store";
import { toggleReportDialog } from "../../stores/videos/report";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { pathsWithRules } from "../../constant";
import { resetAuth, resetPosts } from "../../stores/videos/store";

const MuiAppBar = () => {
  const isLoggedIn = useStore(Store.$isLoggedIn);
  const location = useLocation();
  const navigator = useNavigate();
  const isMainSection = location.pathname === "/";

  const onLogoutClick = () => {
    resetAuth();
    resetPosts();
    navigator("/");
  };

  return (
    <AppBar
      position="static"
      style={{ height: 60, display: "flex", justifyContent: "center" }}
    >
      <Toolbar id="back-to-top-anchor" variant="dense">
        <Navs>
          <Nav to={pathsWithRules.main.url()}>Управление трансляциями</Nav>
          <Nav to={pathsWithRules.users.url()}>Пользователи</Nav>
          <Nav to={pathsWithRules.rules.url()}>Правила</Nav>
        </Navs>
        <div>
          {isLoggedIn && isMainSection && (
            <>
              <Button
                size="small"
                onClick={() => toggleReportDialog()}
                variant="contained"
                startIcon={<MatIcons.GetApp />}
                color="secondary"
                style={{ margin: "15px" }}
              >
                Сформировать отчет
              </Button>
              <IconButton
                color="inherit"
                aria-label="settings"
                onClick={() => Store.setPriorityOpen(true)}
              >
                <MatIcons.Settings />
              </IconButton>
            </>
          )}
          {isLoggedIn && (
            <IconButton
              color="inherit"
              aria-label="exit"
              onClick={onLogoutClick}
            >
              <MatIcons.ExitToApp />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

const Navs = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexGrow: 1,
});

const Nav = styled(NavLink)((props) => ({
  color: props.theme.palette.common.white,
  textDecoration: "none",
  fontSize: 16,
  marginLeft: 16,
  "&.active": {
    fontWeight: "bold",
    fontSize: 18,
  },
  "&:first-child": {
    marginLeft: 0,
  },
  "&:not(.active)": {
    position: "relative",
  },
}));

export default MuiAppBar;
