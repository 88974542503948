import {
  styled,
  Box,
  TableHead as OriginalTableHead,
  TableBody as OriginalTableBody,
  Chip,
} from "@material-ui/core";

export const Loading = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const TableHead = styled(OriginalTableHead)({
  textTransform: "uppercase",
});

export const TableBody = styled(OriginalTableBody)({
  "& .source": {
    maxWidth: 120,
  },
  "& .sportIds": {
    maxWidth: 180,
  },
  "& .champIds": {
    maxWidth: 200,
  },
  "& .addedZones": {
    maxWidth: 250,
  },
  "& .addedPlatform": {
    maxWidth: 250,
  },
  "& .deletedZones": {
    maxWidth: 250,
  },
  "& .deletedPlatform": {
    maxWidth: 250,
  },
  "& .actions": {
    minWidth: 160,
  },
});

export const Chips = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  margin: -2,
  "& > *": {
    margin: 2,
  },
});
