import {
  styled,
  Box,
  TableHead as OriginalTableHead,
  Chip,
} from "@material-ui/core";

export const Loading = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const TableHead = styled(OriginalTableHead)({
  textTransform: "uppercase",
});

export const Blocked = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
}));

export const Roles = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  "& > *": {
    "& + *": {
      marginLeft: 4,
    },
  },
});
