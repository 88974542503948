import React, { useEffect } from 'react'
import { RulesTable } from '../features/rules'
import { Box, Grid } from '@material-ui/core'
import { RuleCreateButton } from '../features/rules/rule-create-button'
import { useStore } from 'effector-react'
import { getProviders } from '../stores/videos/store'
import * as Store from '../stores/videos/store'

export function Rules() {
  const isLoggedIn = useStore(Store.$isLoggedIn)

  useEffect(() => {
    if (isLoggedIn) void getProviders()
  }, [isLoggedIn])

  return (
    <Box style={{ padding: 10 }}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <RuleCreateButton />
        </Grid>
        <Grid item>
          <RulesTable />
        </Grid>
      </Grid>
    </Box>
  )
}
