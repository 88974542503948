import * as Effector from "effector";
import axios from "axios";
import { restore, sample } from 'effector'
import { getUserFx } from '../user'

axios.defaults.baseURL = "admin";

export const $priorities = Effector.createStore({});
export const setPriorityOpen = Effector.createEvent();
export const $priorityOpen = Effector.createStore(false).on(
  setPriorityOpen,
  (_state, data) => data
);

export const getPriorities = Effector.createEffect({
  handler: async () => {
    const res = await axios({
      method: "get",
      url: "config",
      auth: $auth.getState(),
    });

    return res.data;
  },
});

export const getProviders = Effector.createEffect({
  handler: async () => {
    const res = await axios({
      method: "get",
      url: "config/enums",
      auth: $auth.getState(),
    });

    return res.data;
  },
});

export const $providers = restore(
  getProviders.doneData.map((data) => data.SourceEnums),
  []
);
export const $zones = restore(
  getProviders.doneData.map((data) => data.Zones),
  []
);
export const $platforms = restore(
  getProviders.doneData.map((data) => data.Platforms),
  []
);

export const updatePrioritiesFx = Effector.createEffect({
  handler: async (priorities) => {
    const res = await axios({
      method: "post",
      url: "config",
      auth: $auth.getState(),
      data: {
        config: priorities,
      },
    });

    return res.data;
  },
});

export const setPriorities = Effector.createEvent();
export const updatePriorities = Effector.createEvent();

Effector.sample({
  clock: updatePriorities,
  source: $priorities,
  fn: (priorities, { zone, providers }) => {
    return {
      ...priorities,
      [zone]: providers,
    };
  },
  target: updatePrioritiesFx,
});

$priorities
  .on(
    [getPriorities.doneData, updatePrioritiesFx.doneData],
    (_state, data) => data.config
  )
  .on(setPriorities, (state, data) => ({ ...state, ...data }));

export const logIn = Effector.createEffect({
  handler: async (auth) => {
    const res = await axios({
      method: "get",
      url: "login",
      auth: auth,
    });

    return res;
  },
});

export const setAuth = Effector.createEvent();
export const resetAuth = Effector.createEvent();

export const setIsLoggedIn = Effector.createEvent();
export const $isLoggedIn = Effector.createStore(false)
  .on(setIsLoggedIn, (_state, data) => data)
  .on(logIn.doneData, (_state, data) => {
    setIsLoggedIn(true);
    localStorage.setItem("auth", JSON.stringify(data.config.auth));
  })
  .on(logIn.fail, () => {
    setIsLoggedIn(false);
    localStorage.clear();
  }).reset(resetAuth);

export const $username = Effector.createStore(null);
export const setUsername = Effector.createEvent();
$username
  .on(setUsername, (_state, data) => data)
  .on(logIn.fail, () => resetAuth())
  .reset(resetAuth);

export const $password = Effector.createStore(null);
export const setPassword = Effector.createEvent();
$password
  .on(setPassword, (_state, data) => data)
  .on(logIn.fail, () => resetAuth())
  .reset(resetAuth);

resetAuth.watch(() => {
  localStorage.clear()

  // https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf("=")
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
  window.location.href = '/'
})

export const $auth = Effector.combine({
  username: $username,
  password: $password,
}).on(setAuth, (_state, data) => data);

Effector.split($auth, {
  both: ({ username, password }) => username && password,
}).both.watch((state) => {
  logIn(state)
});

sample({
  clock: logIn.done,
  source: $auth,
  fn: (auth) => ({ auth }),
  target: getUserFx,
})

export const fetchVideos = Effector.createEffect({
  handler: async (params) => {
    const res = await axios({
      method: "post",
      url: "page",
      auth: $auth.getState(),
      data: params,
    });

    // let res = {};
    // res.data = fakeData;

    return res.data;
  },
});

export const $currentPage = Effector.createStore(1);
export const setCurrentPage = Effector.createEvent();
export const resetCurrentPage = Effector.createEvent();

$currentPage
  .on(setCurrentPage, (_state, currentPage) => currentPage)
  .reset(resetCurrentPage);

$currentPage.updates.watch((state) => {
  localStorage.setItem("currentPage", state);
});

export const $postsPerPage = Effector.createStore(100);
export const setPostsPerPage = Effector.createEvent();

$postsPerPage
  .on(setPostsPerPage, (_state, postsPerPage) => postsPerPage)
  .updates.watch((state) => {
    resetCurrentPage();
    localStorage.setItem("postsPerPage", state);
  });

export const $liveFilter = Effector.createStore(null);
export const setLiveFilter = Effector.createEvent();
export const resetLiveFilter = Effector.createEvent();

$liveFilter
  .on(setLiveFilter, (_state, liveFilter) => liveFilter)
  .reset(resetLiveFilter)
  .updates.watch((state) => {
    resetCurrentPage();
    localStorage.setItem("liveFilter", state);
  });

export const $sourcesFilter = Effector.createStore([]);
export const setSourcesFilter = Effector.createEvent();
export const resetSourcesFilter = Effector.createEvent();

$sourcesFilter
  .on(setSourcesFilter, (_state, sourcesFilter) => sourcesFilter)
  .reset(resetSourcesFilter)
  .updates.watch((state) => {
    resetCurrentPage();
    localStorage.setItem("sourcesFilter", JSON.stringify(state));
  });

export const $olimpIdsFilter = Effector.createStore("");
export const setOlimpIdsFilter = Effector.createEvent();
export const resetOlimpIdsFilter = Effector.createEvent();

$olimpIdsFilter
  .on(setOlimpIdsFilter, (_state, olimpIdsFilter) => olimpIdsFilter)
  .reset(resetOlimpIdsFilter)
  .updates.watch((state) => {
    resetCurrentPage();
  });

export const $sortOptions = Effector.createStore({});
export const setSortOptions = Effector.createEvent();
export const resetSortOptions = Effector.createEvent();

$sortOptions
  .on(setSortOptions, (_state, sortOptions) => sortOptions)
  .reset(resetSortOptions)
  .updates.watch((state) => {
    resetCurrentPage();
    localStorage.setItem("sortOptions", JSON.stringify(state));
  });

export const resetFetchParams = Effector.createEvent();
export const setFetchParams = Effector.createEvent();

export const $fetchParams = Effector.createStore({
  page: 1,
  size: 10,
  live: null,
  source: [],
  start: null,
  sort: {},
  olimpId: "",
  binded: null,
  id: "",
  name: "",
});

$fetchParams
  .on(setFetchParams, (state, data) => ({
    ...state,
    ...data,
    page: data.page || 1,
  }))
  .reset(resetFetchParams)
  .updates.watch((state) => {
    localStorage.setItem("fetchParams", JSON.stringify(state));
    resetPosts();
    $isLoggedIn.getState() && fetchVideos(state);
  });

export const update = Effector.createEffect({
  handler: async ({ id, index, key, value }) => {
    const res = await axios({
      method: "post",
      url: "" + id,
      auth: $auth.getState(),
      data: {
        [key]: value,
      },
    });
    return { index: index, data: res.data };
  },
});

export const reset = Effector.createEffect({
  handler: async (id) => {
    const res = await axios({
      method: "post",
      url: `${id}/reset`,
      auth: $auth.getState(),
    });
    return res.data;
  },
});

export const $posts = Effector.createStore([]);
export const setPosts = Effector.createEvent();
export const resetPosts = Effector.createEvent();

$posts
  .on(setPosts, (_state, posts) => posts)
  .on(fetchVideos.doneData, (state, data) => data.content)
  .on(update.doneData, (state, data) =>
    state.map((e, i) => (i === data.index ? data.data : e))
  )
  .on(reset.doneData, (state, payload) =>
    state.map((video) => (video.id === payload.id ? payload : video))
  )
  .reset(resetPosts);

export const $pagesTotal = Effector.createStore(1).on(
  fetchVideos.doneData,
  (_state, data) => data.totalPages
);

export const tryFetchVideos = Effector.createEvent();

Effector.guard({
  source: Effector.sample($fetchParams, tryFetchVideos),
  filter: $isLoggedIn,
  target: fetchVideos,
});
