/** @format */

import React from "react";
import { Dialog, DialogTitle, DialogContent, Box } from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import { useStore } from "effector-react";
import {
  $videoPlayer,
  closeVideoPlayer,
} from "../../stores/videos/video-player";
import { styles } from "../../app/theme";

const VideoPlayerDialog = () => {
  const { isOpened, videoUrl } = useStore($videoPlayer);
  const classes = styles();

  if (!isOpened || !videoUrl) return null;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{ elevation: 4 }}
        open={isOpened}
        onClose={() => closeVideoPlayer()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <div>Видеотрансляция</div>
            <MatIcons.Close
              style={{ cursor: "pointer" }}
              onClick={() => closeVideoPlayer()}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <iframe
              src={videoUrl}
              title={videoUrl}
              className={classes.iframe}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default VideoPlayerDialog;
