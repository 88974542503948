import { CircularProgress, Button, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import React from "react";
import PageControls from "../features/videos/page-controls";
import LoginDialog from "../features/authorization/login-dialog";
import { styles } from "../app/theme";
import PlatformPopper from "../features/videos/platform-popper";
import ZonePopper from "../features/videos/zone-popper";
import PriorityDialog from "../features/videos/priority-dialog";
import SearchPopper from "../features/videos/search-popper";
import ManualPopper from "../features/videos/manual-popper";
import CustomVideoDialog from "../features/videos/custom-video-dialog";
import * as Platform from "../stores/videos/platform";
import { resetPosts } from '../stores/videos/store'
import * as Zone from "../stores/videos/zone";
import * as Search from "../stores/videos/search";
import * as Manual from "../stores/videos/manual";
import * as Store from "../stores/videos/store";
import TopControls from "../features/videos/top-controls";
import Videos from "../features/videos/videos";
import HeaderRow from "../features/videos/header-row";
import { RequestModal } from "../features/videos/request-modal";
import {
  $customVideoDialog,
  openCustomVideoDialog,
} from "../stores/videos/custom-video";
import Description from "../features/videos/description";
import VideoPlayerDialog from "../features/videos/video-player-dialog";
import { $videoPlayer } from "../stores/videos/video-player";
import { $reportDialog } from "../stores/videos/report";
import { ReportDialog } from "../features/videos/report";

export function Main() {
  const isLoggedIn = useStore(Store.$isLoggedIn);
  const searchAnchorEl = useStore(Search.$anchorEl);
  const manualAnchorEl = useStore(Manual.$anchorEl);
  const platformAnchorEl = useStore(Platform.$anchorEl);
  const zoneAnchorEl = useStore(Zone.$anchorEl);
  const priorityOpen = useStore(Store.$priorityOpen);
  const { isOpened: isCustomVideoDialogOpen } = useStore($customVideoDialog);
  const isReportDialogOpen = useStore($reportDialog);
  const { isOpened: isVideoPlayerOpen } = useStore($videoPlayer);
  const isVideosFetching = useStore(Store.fetchVideos.pending);
  const classes = styles();
  const posts = useStore(Store.$posts);

  React.useEffect(() => {
    if (isLoggedIn) {
      Store.tryFetchVideos();
      Store.getProviders();
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    return () => {
      resetPosts()
    }
  }, []);

  return (
    <>
      {isLoggedIn && (
        <div style={{ margin: 10 }}>
          <TopControls />

          <Button
            onClick={() => openCustomVideoDialog()}
            variant="contained"
            color="secondary"
            style={{ margin: "15px 0" }}
          >
            Добавить трансляцию
          </Button>

          <PageControls />
          <div>
            <HeaderRow />
            {isVideosFetching && (
              <CircularProgress
                size={200}
                color="secondary"
                style={{ display: "flex", margin: "50px auto" }}
              />
            )}
            {!isVideosFetching && posts.length === 0 && (
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                align="center"
                className={classes.empty}
              >
                По данным критериям трансляций не найдено
              </Typography>
            )}

            {posts.map((post, index) => (
              <Videos
                /* 
                // @ts-ignore */
                key={post.id}
                post={post}
                index={index}
              />
            ))}
          </div>
          <PageControls />
          <Description />

          {searchAnchorEl && <SearchPopper />}
          {manualAnchorEl && <ManualPopper />}
          {platformAnchorEl && <PlatformPopper />}
          {zoneAnchorEl && <ZonePopper />}
          {priorityOpen && <PriorityDialog />}
          {isCustomVideoDialogOpen && <CustomVideoDialog />}
          {isReportDialogOpen && <ReportDialog />}
          {isVideoPlayerOpen && <VideoPlayerDialog />}

          <RequestModal />
        </div>
      )}

      {!isLoggedIn && localStorage.getItem("auth") ? (
        <CircularProgress
          size={200}
          color="secondary"
          style={{ display: "flex", margin: "20% auto" }}
        />
      ) : !isLoggedIn ? (
        <LoginDialog />
      ) : null}
    </>
  );
}
