import { createEffect, createEvent, createStore } from "effector";
import { adminApi, types } from "../api";

// effects
export const getUsersFx = createEffect({ handler: adminApi.getAllUsers });
export const createUserFx = createEffect({ handler: adminApi.createUser });
export const updateUserFx = createEffect({
  handler: ({ id, ...payload }: types.UserUpdateRequest & { id: string }) =>
    adminApi.updateUser(id, payload),
});
export const blockUserFx = createEffect({
  handler: ({ id, isBlocked }: { id: string; isBlocked: boolean }) =>
    adminApi.setBlock(id, { isBlocked }),
});

// events
export const formStateChangedEv = createEvent<types.UserUpdateRequest>();
export const formStateResetEv = createEvent();

// stores
export const $users = createStore<types.UserResponse[]>([]);
export const $formState = createStore<
  types.UserUpdateRequest | types.UserCreateRequest
>({});

// logics
$users
  .on(getUsersFx.doneData, (_, { data }) => data)
  .on(blockUserFx.done, (users, { params }) =>
    users.map((user) =>
      user.id === params.id ? { ...user, isBlocked: params.isBlocked } : user
    )
  );

$formState
  .on(formStateChangedEv, (state, payload) => ({ ...state, ...payload }))
  .reset(formStateResetEv);

createUserFx.done.watch(() => getUsersFx());
updateUserFx.done.watch(() => getUsersFx());
