/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IframeVideoUpdate {
  /** @format int64 */
  streamId?: number;
  name?: string;
  /** @format int32 */
  sportId?: number;
  sportName?: string;
  code?: string;
  /** @format int64 */
  startTime?: number;
  /** @uniqueItems true */
  allowedPlatforms?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  /** @uniqueItems true */
  removeForZone?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
}

export interface AdminVideoEntity {
  id: ObjectId;
  /** @format int64 */
  streamId: number;
  src:
    | "BETRADAR"
    | "UNAS"
    | "PERFORM"
    | "IMG"
    | "SPORTBOOM"
    | "OKKO"
    | "SPORTLEVEL"
    | "RPLUMA"
    | "BETCONSTRUCT"
    | "SPORTBOOMSPECIAL"
    | "FIFA1X"
    | "OKKOORIG"
    | "BETGENIUS"
    | "IFRAME"
    | "DIGITAIN"
    | "ESL"
    | "IGAMEMEDIA";
  name: string;
  champName?: string;
  sportId: number[];
  sportName?: string;
  olimpIds: Record<string, string>;
  linkedOlimpIds: Record<string, string>;
  code?: object;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  finishTime?: string;
  live: boolean;
  /** @uniqueItems true */
  removeForZone: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  allowedPlatforms: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  cancelled: boolean;
  isCustomEntity: boolean;
  allowResetManualUpdates: boolean;
  url: string;
}

export interface AdminVideoResponse {
  entity?: AdminVideoEntity;
  message?: string;
}

export interface ObjectId {
  /** @format int32 */
  timestamp?: number;
  /** @format date-time */
  date?: string;
}

export interface VideoUpdate {
  olimpIds?: Record<string, string>;
  live?: boolean;
  /** @uniqueItems true */
  removeForZone?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  allowedPlatforms?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
}

export interface UserCreateRequest {
  /** @pattern ^[a-zA-Zа-яА-ЯёЁ]+$ */
  userName: string;
  /** @pattern ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()_+=#?!@$%^&*-]).{6,}$ */
  password: string;
  /** @uniqueItems true */
  roles: ("USER" | "ADMIN")[];
}

export interface HistoricalChange {
  propertyName: string;
  oldValue?: object;
  newValue?: object;
}

export interface UserHistoricalChange {
  authorOfChanging: string;
  dateTime: string;
  historicalChange: HistoricalChange[];
}

export interface UserResponse {
  id?: ObjectId;
  username?: string;
  isBlocked?: boolean;
  author?: string;
  creationDateTime?: string;
  /** @uniqueItems true */
  roles?: ("USER" | "ADMIN")[];
  historicalChanges?: UserHistoricalChange[];
}

export interface VideoSearch {
  /**
   * @format int32
   * @min 1
   */
  page: number;
  /**
   * @format int32
   * @min 1
   * @max 10000
   */
  size: number;
  sportIds: number[];
  /**
   * @format int64
   * @min 0
   */
  start?: number;
  query?: string;
}

export interface IndexPageMatchIndexDocument {
  /** @format int64 */
  total: number;
  match: MatchIndexDocument[];
}

export interface MatchIndexDocument {
  id: string;
  /** @format int64 */
  sportId: number;
  live: boolean;
  /** @format int64 */
  start: number;
  /** @format int64 */
  brId?: number;
  nameRu?: string;
  tournamentRu?: string;
  team1NameRu?: string;
  team2NameRu?: string;
  nameEn?: string;
  tournamentEn?: string;
  team1NameEn?: string;
  team2NameEn?: string;
  /** @format int64 */
  linkedMatch?: number;
  /** @format int64 */
  lastUpdate: number;
  /** @format float */
  score?: number;
  /** @format int64 */
  trueLinkedMatch?: number;
}

export interface VideoRuleCreateRequest {
  source:
    | "BETRADAR"
    | "UNAS"
    | "PERFORM"
    | "IMG"
    | "SPORTBOOM"
    | "OKKO"
    | "SPORTLEVEL"
    | "RPLUMA"
    | "BETCONSTRUCT"
    | "SPORTBOOMSPECIAL"
    | "FIFA1X"
    | "OKKOORIG"
    | "BETGENIUS"
    | "IFRAME"
    | "DIGITAIN"
    | "ESL"
    | "IGAMEMEDIA";
  /** @uniqueItems true */
  sportIds?: number[];
  /** @uniqueItems true */
  champIds?: string[];
  /** @uniqueItems true */
  addedZones?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  addedPlatform?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  /** @uniqueItems true */
  deletedZones?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  deletedPlatform?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
}

export interface Order {
  direction?: "ASC" | "DESC";
  property?: string;
  ignoreCase?: boolean;
  nullHandling?: "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";
  ascending?: boolean;
  descending?: boolean;
}

export interface VideoPage {
  /**
   * @format int64
   * @min 1
   */
  page: number;
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  size: number;
  /**
   * @maxItems 2
   * @minItems 2
   */
  start?: number[];
  sort: Record<string, boolean>;
  source: (
    | "BETRADAR"
    | "UNAS"
    | "PERFORM"
    | "IMG"
    | "SPORTBOOM"
    | "OKKO"
    | "SPORTLEVEL"
    | "RPLUMA"
    | "BETCONSTRUCT"
    | "SPORTBOOMSPECIAL"
    | "FIFA1X"
    | "OKKOORIG"
    | "BETGENIUS"
    | "IFRAME"
    | "DIGITAIN"
    | "ESL"
    | "IGAMEMEDIA"
  )[];
  live?: boolean;
  cancelled?: boolean;
  active?: boolean;
  /**
   * @format int64
   * @min 1
   */
  sportId?: number;
  /**
   * @format int64
   * @min 1
   */
  olimpId?: number;
  binded?: boolean;
  /**
   * @format int64
   * @min 1
   */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 200
   */
  name?: string;
  /** @format date-time */
  from?: string;
  /** @format date-time */
  to?: string;
  sortOrders: Order[];
}

export interface PageAdminVideoEntity {
  /** @format int64 */
  page: number;
  /** @format int32 */
  size: number;
  /** @format int64 */
  total: number;
  content: AdminVideoEntity[];
  /** @format int64 */
  totalPages: number;
}

export interface IframeVideoCreate {
  /** @format int64 */
  streamId: number;
  name: string;
  /** @format int32 */
  sportId?: number;
  sportName: string;
  code: string;
  /** @format int64 */
  startTime: number;
  /** @uniqueItems true */
  allowedPlatforms: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  /** @uniqueItems true */
  removeForZone: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
}

export interface AdminVideoConfigRequest {
  config: Record<
    string,
    (
      | "BETRADAR"
      | "UNAS"
      | "PERFORM"
      | "IMG"
      | "SPORTBOOM"
      | "OKKO"
      | "SPORTLEVEL"
      | "RPLUMA"
      | "BETCONSTRUCT"
      | "SPORTBOOMSPECIAL"
      | "FIFA1X"
      | "OKKOORIG"
      | "BETGENIUS"
      | "IFRAME"
      | "DIGITAIN"
      | "ESL"
      | "IGAMEMEDIA"
    )[]
  >;
}

export interface VideoConfig {
  config: Record<
    string,
    (
      | "BETRADAR"
      | "UNAS"
      | "PERFORM"
      | "IMG"
      | "SPORTBOOM"
      | "OKKO"
      | "SPORTLEVEL"
      | "RPLUMA"
      | "BETCONSTRUCT"
      | "SPORTBOOMSPECIAL"
      | "FIFA1X"
      | "OKKOORIG"
      | "BETGENIUS"
      | "IFRAME"
      | "DIGITAIN"
      | "ESL"
      | "IGAMEMEDIA"
    )[]
  >;
  currentCollectNode?: string;
  /** @format int64 */
  lastCollect: number;
  id?: ObjectId;
}

export interface PageMatchIndexDocument {
  /** @format int64 */
  page: number;
  /** @format int32 */
  size: number;
  /** @format int64 */
  total: number;
  content: MatchIndexDocument[];
  /** @format int64 */
  totalPages: number;
}

export interface UserUpdateRequest {
  /** @pattern ^[a-zA-Zа-яА-ЯёЁ]+$ */
  userName?: string;
  /** @pattern ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[()_+=#?!@$%^&*-]).{6,}$ */
  password?: string;
  /** @uniqueItems true */
  roles?: ("USER" | "ADMIN")[];
}

export interface VideoRuleUpdateRequest {
  source:
    | "BETRADAR"
    | "UNAS"
    | "PERFORM"
    | "IMG"
    | "SPORTBOOM"
    | "OKKO"
    | "SPORTLEVEL"
    | "RPLUMA"
    | "BETCONSTRUCT"
    | "SPORTBOOMSPECIAL"
    | "FIFA1X"
    | "OKKOORIG"
    | "BETGENIUS"
    | "IFRAME"
    | "DIGITAIN"
    | "ESL"
    | "IGAMEMEDIA";
  /** @uniqueItems true */
  sportIds?: number[];
  /** @uniqueItems true */
  champIds?: string[];
  /** @uniqueItems true */
  addedZones?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  addedPlatform?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  /** @uniqueItems true */
  deletedZones?: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  deletedPlatform?: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
}

export interface ResponseDataResponseUrl {
  /** @format int32 */
  code: number;
  desc?: string;
  data?: ResponseUrl;
}

export interface ResponseUrl {
  url?: string;
  /** @format int32 */
  provider?: number;
  format?: string;
  error?: string;
  status?:
    | "100 CONTINUE"
    | "101 SWITCHING_PROTOCOLS"
    | "102 PROCESSING"
    | "103 CHECKPOINT"
    | "200 OK"
    | "201 CREATED"
    | "202 ACCEPTED"
    | "203 NON_AUTHORITATIVE_INFORMATION"
    | "204 NO_CONTENT"
    | "205 RESET_CONTENT"
    | "206 PARTIAL_CONTENT"
    | "207 MULTI_STATUS"
    | "208 ALREADY_REPORTED"
    | "226 IM_USED"
    | "300 MULTIPLE_CHOICES"
    | "301 MOVED_PERMANENTLY"
    | "302 FOUND"
    | "302 MOVED_TEMPORARILY"
    | "303 SEE_OTHER"
    | "304 NOT_MODIFIED"
    | "305 USE_PROXY"
    | "307 TEMPORARY_REDIRECT"
    | "308 PERMANENT_REDIRECT"
    | "400 BAD_REQUEST"
    | "401 UNAUTHORIZED"
    | "402 PAYMENT_REQUIRED"
    | "403 FORBIDDEN"
    | "404 NOT_FOUND"
    | "405 METHOD_NOT_ALLOWED"
    | "406 NOT_ACCEPTABLE"
    | "407 PROXY_AUTHENTICATION_REQUIRED"
    | "408 REQUEST_TIMEOUT"
    | "409 CONFLICT"
    | "410 GONE"
    | "411 LENGTH_REQUIRED"
    | "412 PRECONDITION_FAILED"
    | "413 PAYLOAD_TOO_LARGE"
    | "413 REQUEST_ENTITY_TOO_LARGE"
    | "414 URI_TOO_LONG"
    | "414 REQUEST_URI_TOO_LONG"
    | "415 UNSUPPORTED_MEDIA_TYPE"
    | "416 REQUESTED_RANGE_NOT_SATISFIABLE"
    | "417 EXPECTATION_FAILED"
    | "418 I_AM_A_TEAPOT"
    | "419 INSUFFICIENT_SPACE_ON_RESOURCE"
    | "420 METHOD_FAILURE"
    | "421 DESTINATION_LOCKED"
    | "422 UNPROCESSABLE_ENTITY"
    | "423 LOCKED"
    | "424 FAILED_DEPENDENCY"
    | "425 TOO_EARLY"
    | "426 UPGRADE_REQUIRED"
    | "428 PRECONDITION_REQUIRED"
    | "429 TOO_MANY_REQUESTS"
    | "431 REQUEST_HEADER_FIELDS_TOO_LARGE"
    | "451 UNAVAILABLE_FOR_LEGAL_REASONS"
    | "500 INTERNAL_SERVER_ERROR"
    | "501 NOT_IMPLEMENTED"
    | "502 BAD_GATEWAY"
    | "503 SERVICE_UNAVAILABLE"
    | "504 GATEWAY_TIMEOUT"
    | "505 HTTP_VERSION_NOT_SUPPORTED"
    | "506 VARIANT_ALSO_NEGOTIATES"
    | "507 INSUFFICIENT_STORAGE"
    | "508 LOOP_DETECTED"
    | "509 BANDWIDTH_LIMIT_EXCEEDED"
    | "510 NOT_EXTENDED"
    | "511 NETWORK_AUTHENTICATION_REQUIRED";
}

export interface ResponseDataMapStringInteger {
  /** @format int32 */
  code: number;
  desc?: string;
  data?: Record<string, number>;
}

export interface ResponseDataMapStringMapStringInteger {
  /** @format int32 */
  code: number;
  desc?: string;
  data?: Record<string, Record<string, number>>;
}

export interface ResponseDataMapStringMapStringBoolean {
  /** @format int32 */
  code: number;
  desc?: string;
  data?: Record<string, Record<string, boolean>>;
}

export interface VideoRuleResponse {
  id?: ObjectId;
  source:
    | "BETRADAR"
    | "UNAS"
    | "PERFORM"
    | "IMG"
    | "SPORTBOOM"
    | "OKKO"
    | "SPORTLEVEL"
    | "RPLUMA"
    | "BETCONSTRUCT"
    | "SPORTBOOMSPECIAL"
    | "FIFA1X"
    | "OKKOORIG"
    | "BETGENIUS"
    | "IFRAME"
    | "DIGITAIN"
    | "ESL"
    | "IGAMEMEDIA";
  /** @uniqueItems true */
  sportIds: number[];
  /** @uniqueItems true */
  champIds: string[];
  /** @uniqueItems true */
  addedZones: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  addedPlatform: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
  /** @uniqueItems true */
  deletedZones: (
    | "BET_RU"
    | "BET_KZ"
    | "BET_UA"
    | "BET_OTHER"
    | "COM_RU"
    | "COM_KZ"
    | "COM_UA"
    | "COM_OTHER"
    | "KZ_RU"
    | "KZ_KZ"
    | "KZ_UA"
    | "KZ_OTHER"
    | "ASIA_RU"
    | "ASIA_KZ"
    | "ASIA_UA"
    | "ASIA_OTHER"
    | "UZ_RU"
    | "UZ_KZ"
    | "UZ_UA"
    | "UZ_OTHER"
  )[];
  /** @uniqueItems true */
  deletedPlatform: (
    | "BET"
    | "BETMOB"
    | "BETAPP"
    | "COM"
    | "COMMOB"
    | "COMAPP"
    | "KZ"
    | "KZMOB"
    | "KZAPP"
    | "ASIA"
    | "ASIAMOB"
    | "ASIAAPP"
    | "UZ"
    | "UZAPP"
    | "UZMOB"
  )[];
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://video-back.olimp.dev" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title API
 * @version 1.5
 * @baseUrl http://video-back.olimp.dev
 *
 * API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * No description
     *
     * @tags Создание трансляций вручную (IFRAME).
     * @name Update
     * @summary Редактирование видеотрансляции.
     * @request PUT:/admin/iframe/{streamId}
     * @secure
     */
    update: (streamId: number, data: IframeVideoUpdate, params: RequestParams = {}) =>
      this.request<AdminVideoResponse, any>({
        path: `/admin/iframe/${streamId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Создание трансляций вручную (IFRAME).
     * @name Delete
     * @summary Удаление видеотрансляции.
     * @request DELETE:/admin/iframe/{streamId}
     * @secure
     */
    delete: (streamId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/iframe/${streamId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name Update1
     * @summary Обновление выбранной видеотрансляции.
     * @request POST:/admin/{id}
     * @secure
     */
    update1: (id: string, data: VideoUpdate, params: RequestParams = {}) =>
      this.request<AdminVideoEntity, any>({
        path: `/admin/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name Reset
     * @summary Сбросить ручные настройки выбранной видеотрансляции.
     * @request POST:/admin/{id}/reset
     * @secure
     */
    reset: (id: string, params: RequestParams = {}) =>
      this.request<AdminVideoEntity, any>({
        path: `/admin/${id}/reset`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование пользователей.
     * @name GetAllUsers
     * @summary Получение списка всех пользователей.
     * @request GET:/admin/users
     */
    getAllUsers: (params: RequestParams = {}) =>
      this.request<UserResponse[], any>({
        path: `/admin/users`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование пользователей.
     * @name CreateUser
     * @summary Создание нового пользователя.
     * @request POST:/admin/users
     */
    createUser: (data: UserCreateRequest, params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/admin/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name SearchDebug
     * @request POST:/admin/search-debug
     * @secure
     */
    searchDebug: (data: VideoSearch, params: RequestParams = {}) =>
      this.request<IndexPageMatchIndexDocument, any>({
        path: `/admin/search-debug`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование правил настроек видео.
     * @name GetAllRules
     * @summary Получение всех правил из БД.
     * @request GET:/admin/rules
     */
    getAllRules: (params: RequestParams = {}) =>
      this.request<VideoRuleResponse[], any>({
        path: `/admin/rules`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование правил настроек видео.
     * @name CreateRule
     * @summary Добавление нового правила в БД.
     * @request POST:/admin/rules
     */
    createRule: (data: VideoRuleCreateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/rules`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name GetPage
     * @summary Получить страницу с видеотрансляциями согласно переданным фильтрам.
     * @request POST:/admin/page
     * @secure
     */
    getPage: (data: VideoPage, params: RequestParams = {}) =>
      this.request<PageAdminVideoEntity, any>({
        path: `/admin/page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Создание трансляций вручную (IFRAME).
     * @name Create
     * @summary Создание новой видеотрансляций.
     * @request POST:/admin/iframe
     * @secure
     */
    create: (data: IframeVideoCreate, params: RequestParams = {}) =>
      this.request<AdminVideoResponse, any>({
        path: `/admin/iframe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Получение всех конфигураций в виде мапы [зона : список поставщиков].
     *
     * @tags Конфигурация по зонам и платформам.
     * @name GetConfig
     * @summary Получение всех конфигураций.
     * @request GET:/admin/config
     * @secure
     */
    getConfig: (params: RequestParams = {}) =>
      this.request<VideoConfig, any>({
        path: `/admin/config`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Обновление конфигураций согласно запросу и затем получение всех конфигураций в виде списка из мап [зона : {список поставщиков}].
     *
     * @tags Конфигурация по зонам и платформам.
     * @name UpdateConfig
     * @summary Обновление конфигураций.
     * @request POST:/admin/config
     * @secure
     */
    updateConfig: (data: AdminVideoConfigRequest, params: RequestParams = {}) =>
      this.request<VideoConfig, any>({
        path: `/admin/config`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name SearchBindingByKzApi1
     * @request POST:/admin/bind/search
     * @secure
     */
    searchBindingByKzApi1: (data: VideoSearch, params: RequestParams = {}) =>
      this.request<PageMatchIndexDocument, any>({
        path: `/admin/bind/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование пользователей.
     * @name UpdateUser
     * @summary Изменение уже существующего пользователя.
     * @request PATCH:/admin/users/{id}
     */
    updateUser: (id: string, data: UserUpdateRequest, params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/admin/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование пользователей.
     * @name SetBlock
     * @summary Блокирование/разблокирование пользователя.
     * @request PATCH:/admin/users/block/{id}
     */
    setBlock: (
      id: string,
      query: {
        isBlocked: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponse, any>({
        path: `/admin/users/block/${id}`,
        method: "PATCH",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование правил настроек видео.
     * @name DeleteRule
     * @summary Удаление правила по id.
     * @request DELETE:/admin/rules/{id}
     */
    deleteRule: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/rules/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование правил настроек видео.
     * @name UpdateRule
     * @summary Обновление правила по id.
     * @request PATCH:/admin/rules/{id}
     */
    updateRule: (id: string, data: VideoRuleUpdateRequest, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/rules/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование пользователей.
     * @name GetCurrentUser
     * @summary Получение данных авторизованного пользователя.
     * @request GET:/admin/users/current
     */
    getCurrentUser: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/admin/users/current`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name GetReport
     * @summary Получить CSV отчёт по трансляциям.
     * @request GET:/admin/report
     * @secure
     */
    getReport: (
      query: {
        списокПоставщиковНапримерSourcesImgSourcesEslЕслиПараметрНеПереданВОтчётПопадутТрансляцииВсехПоставщиков?: (
          | "BETRADAR"
          | "UNAS"
          | "PERFORM"
          | "IMG"
          | "SPORTBOOM"
          | "OKKO"
          | "SPORTLEVEL"
          | "RPLUMA"
          | "BETCONSTRUCT"
          | "SPORTBOOMSPECIAL"
          | "FIFA1X"
          | "OKKOORIG"
          | "BETGENIUS"
          | "IFRAME"
          | "DIGITAIN"
          | "ESL"
          | "IGAMEMEDIA"
        )[];
        /**
         * Начало отчётного периода в миллисекундах по UTC.
         * @format int64
         */
        from: number;
        /**
         * Конец отчётного периода в миллисекундах по UTC.
         * @format int64
         */
        to: number;
        /**
         * Разница в часах с UTC-0, например, 6 для Алматы, -5 для Нью-Йорка.
         * @format int32
         * @default 0
         */
        offsetHours?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/admin/report`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Просмотр и редактирование видеотрансляций.
     * @name Login
     * @summary Авторизация пользователей в админке.
     * @request GET:/admin/login
     * @secure
     */
    login: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/admin/login`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Получение названия ноды, выполняющей задания по расписанию.
     *
     * @tags Конфигурация по зонам и платформам.
     * @name GetCurrentNode
     * @request GET:/admin/config/node
     * @secure
     */
    getCurrentNode: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/admin/config/node`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Получение всех зон, платформ и активных поставщиков в виде мапы с тремя ключами: Zones, Platforms, SourceEnums.
     *
     * @tags Конфигурация по зонам и платформам.
     * @name GetEnums
     * @summary Получение всех зон, платформ и активных поставщиков.
     * @request GET:/admin/config/enums
     * @secure
     */
    getEnums: (params: RequestParams = {}) =>
      this.request<Record<string, object>, any>({
        path: `/admin/config/enums`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  api = {
    /**
     * @description Поиск трансляции по переданному идентификатору события и получение URL для последующего + запроса на воспроизведение.
     *
     * @tags Получение URL для воспроизведения трансляции.
     * @name Matches
     * @summary Получение URL для воспроизведения трансляции.
     * @request GET:/api/video/{matchId}
     */
    matches: (matchId: string, params: RequestParams = {}) =>
      this.request<ResponseDataResponseUrl, any>({
        path: `/api/video/${matchId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo
     * @summary Старая реализация. Оставлена для обратной совместимости. Sportlevel. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/sportlevel/{streamId}
     */
    getVideo: (
      streamId: number,
      query?: {
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/sportlevel/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo1
     * @summary Старая реализация. Оставлена для обратной совместимости. SportboomSpecial. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/sportboomspecial/{streamId}
     */
    getVideo1: (
      streamId: number,
      query?: {
        /** @default "frame" */
        format?: string;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/sportboomspecial/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo2
     * @summary Старая реализация. Оставлена для обратной совместимости. Sportboom. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/sportboom/{streamId}
     */
    getVideo2: (
      streamId: number,
      query?: {
        /** @default "frame" */
        format?: string;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/sportboom/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo3
     * @summary Старая реализация. Оставлена для обратной совместимости. Rpluma. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/rpluma/{streamId}
     */
    getVideo3: (
      streamId: string,
      query?: {
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/video/rpluma/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo4
     * @summary Старая реализация. Оставлена для обратной совместимости. Perform. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/perform/{streamId}
     */
    getVideo4: (
      streamId: string,
      query?: {
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/video/perform/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo5
     * @summary Старая реализация. Оставлена для обратной совместимости. Img. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/img/{streamId}
     */
    getVideo5: (
      streamId: number,
      query?: {
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/img/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo6
     * @summary Старая реализация. Оставлена для обратной совместимости. IGameMedia. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/igamemedia/{streamId}
     */
    getVideo6: (
      streamId: number,
      query?: {
        userId?: string;
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/igamemedia/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo7
     * @summary Старая реализация. Оставлена для обратной совместимости. Iframe. Получение HTML c трансляцией.
     * @request GET:/api/video/iframe/{streamId}
     */
    getVideo7: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/video/iframe/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo8
     * @summary Старая реализация. Оставлена для обратной совместимости. Esl. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/esl/{streamId}
     */
    getVideo8: (
      streamId: number,
      query: {
        platform:
          | "BET"
          | "BETMOB"
          | "BETAPP"
          | "COM"
          | "COMMOB"
          | "COMAPP"
          | "KZ"
          | "KZMOB"
          | "KZAPP"
          | "ASIA"
          | "ASIAMOB"
          | "ASIAAPP"
          | "UZ"
          | "UZAPP"
          | "UZMOB";
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/video/esl/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo9
     * @summary Старая реализация. Оставлена для обратной совместимости. Digitain. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/digitain/{streamId}
     */
    getVideo9: (
      streamId: string,
      query?: {
        /** @default "frame" */
        format?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/video/digitain/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo10
     * @summary Старая реализация. Оставлена для обратной совместимости. Betradar. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/betradar/{streamId}
     */
    getVideo10: (
      streamId: string,
      query: {
        platform:
          | "BET"
          | "BETMOB"
          | "BETAPP"
          | "COM"
          | "COMMOB"
          | "COMAPP"
          | "KZ"
          | "KZMOB"
          | "KZAPP"
          | "ASIA"
          | "ASIAMOB"
          | "ASIAAPP"
          | "UZ"
          | "UZAPP"
          | "UZMOB";
        /** @default "frame" */
        format?: string;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/betradar/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo11
     * @summary Старая реализация. Оставлена для обратной совместимости. Betgenius. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/betgenius/{streamId}
     */
    getVideo11: (
      streamId: number,
      query: {
        platform:
          | "BET"
          | "BETMOB"
          | "BETAPP"
          | "COM"
          | "COMMOB"
          | "COMAPP"
          | "KZ"
          | "KZMOB"
          | "KZAPP"
          | "ASIA"
          | "ASIAMOB"
          | "ASIAAPP"
          | "UZ"
          | "UZAPP"
          | "UZMOB";
        country: string;
        /** @default "frame" */
        format?: string;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/betgenius/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetVideo12
     * @summary Старая реализация. Оставлена для обратной совместимости. Betconstruct. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/video/betconstruct/{streamId}
     */
    getVideo12: (
      streamId: number,
      query?: {
        /** @default "frame" */
        format?: string;
        /** @default false */
        mobile?: boolean;
        /** @default false */
        app?: boolean;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/api/video/betconstruct/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Получение мапы, где в качестве ключа - id матча, а значение - порядковый номер поставщика трансляции для этого матча.
     *
     * @tags Список матчей и их трансляций.
     * @name Matches1
     * @summary Получение списка матчей и их трансляций.
     * @request GET:/api/matches
     */
    matches1: (
      query?: {
        live?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseDataMapStringInteger, any>({
        path: `/api/matches`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Контроллер для сборки фида.
     * @name Matches2
     * @summary Получение фидов.
     * @request GET:/api/feed
     * @secure
     */
    matches2: (
      query?: {
        /** @default true */
        live?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseDataMapStringMapStringInteger, any>({
        path: `/api/feed`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Список матчей с доступными трансляциями.
     * @name Broadcast
     * @summary Получение матчей с активными трансляциями с группировкой по зонам
     * @request GET:/api/broadcast
     */
    broadcast: (
      query?: {
        live?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseDataMapStringMapStringBoolean, any>({
        path: `/api/broadcast`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Список матчей с доступными трансляциями.
     * @name GetBroadcast
     * @summary Получение URL и значение параметра "format" для видео с указанным id.
     * @request GET:/api/broadcast/{matchId}
     */
    getBroadcast: (
      matchId: string,
      query?: {
        /** @default false */
        app?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ResponseUrl, any>({
        path: `/api/broadcast/${matchId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe
     * @summary Sportlevel. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/sportlevel/{streamId}
     */
    getIframe: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/sportlevel/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe1
     * @summary SportboomSpecial. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/sportboomspecial/{streamId}
     */
    getIframe1: (
      streamId: number,
      query: {
        origin: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/sportboomspecial/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe2
     * @summary Sportboom. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/sportboom/{streamId}
     */
    getIframe2: (
      streamId: number,
      query: {
        origin: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/sportboom/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe3
     * @summary Rpluma. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/broadcast/rpluma/{streamId}
     */
    getIframe3: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/rpluma/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe4
     * @summary Perform. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/perform/{streamId}
     */
    getIframe4: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/perform/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe5
     * @summary Img. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/broadcast/img/{streamId}
     */
    getIframe5: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/img/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe6
     * @summary IGameMedia. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/broadcast/igamemedia/{streamId}
     */
    getIframe6: (
      streamId: number,
      query?: {
        userId?: string;
        redirectURL?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/igamemedia/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe7
     * @summary Iframe. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/iframe/{streamId}
     */
    getIframe7: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/iframe/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe8
     * @summary Esl. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/esl/{streamId}
     */
    getIframe8: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/esl/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe9
     * @summary Digitain. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/digitain/{streamId}
     */
    getIframe9: (streamId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/broadcast/digitain/${streamId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe10
     * @summary Betradar. Получение HTML c трансляцией или JSON, содержащего URL трансляции.
     * @request GET:/api/broadcast/betradar/{streamId}
     */
    getIframe10: (
      streamId: number,
      query: {
        platform:
          | "BET"
          | "BETMOB"
          | "BETAPP"
          | "COM"
          | "COMMOB"
          | "COMAPP"
          | "KZ"
          | "KZMOB"
          | "KZAPP"
          | "ASIA"
          | "ASIAMOB"
          | "ASIAAPP"
          | "UZ"
          | "UZAPP"
          | "UZMOB";
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/betradar/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe11
     * @summary Betgenius. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/betgenius/{streamId}
     */
    getIframe11: (
      streamId: number,
      query: {
        platform:
          | "BET"
          | "BETMOB"
          | "BETAPP"
          | "COM"
          | "COMMOB"
          | "COMAPP"
          | "KZ"
          | "KZMOB"
          | "KZAPP"
          | "ASIA"
          | "ASIAMOB"
          | "ASIAAPP"
          | "UZ"
          | "UZAPP"
          | "UZMOB";
        country: string;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/betgenius/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Воспроизведение трансляции.
     * @name GetIframe12
     * @summary Betconstruct. Получение HTML c трансляцией.
     * @request GET:/api/broadcast/betconstruct/{streamId}
     */
    getIframe12: (
      streamId: number,
      query?: {
        /** @default false */
        mobile?: boolean;
        /** @default false */
        app?: boolean;
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/broadcast/betconstruct/${streamId}`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
}
