/** @format */

import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { styles } from "../../app/theme";
import {
  $reportDialog,
  $isFormValid,
  $formValues,
  downloadReportFx,
  onChange,
  toggleReportDialog,
  resetForm,
  $error,
} from "../../stores/videos/report";
import { $providers } from "../../stores/videos/store";

export const ReportDialog = () => {
  const isOpened = useStore($reportDialog);
  const values = useStore($formValues);
  const isFormValid = useStore($isFormValid);
  const providers = useStore($providers);
  const isDownloadPending = useStore(downloadReportFx.pending);
  const errorMessage = useStore($error);
  const classes = styles();

  useEffect(() => () => resetForm(), []);

  const onSubmit = () => {
    downloadReportFx({
      from: values.from,
      to: values.to,
      sources: values.providers.join(","),
      offsetHours: (new Date().getTimezoneOffset() / 60) * -1,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        PaperProps={{ elevation: 4 }}
        open={isOpened}
        onClose={() => toggleReportDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Формирование отчета</DialogTitle>

        <DialogContent>
          <div
            className={classes.customVideoSectionTitle}
            style={{ marginBottom: 15 }}
          >
            Временной интервал:
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DatePicker
                format="dd.MM.yy"
                style={{ width: 120 }}
                size="small"
                disableToolbar
                autoOk
                label="Дата, от"
                maxDate={values.to}
                inputVariant="outlined"
                variant="inline"
                value={values.from}
                onChange={(date) => {
                  date.setHours(0);
                  date.setMinutes(0);
                  onChange({ from: new Date(date).getTime() });
                }}
              />
              <div style={{ margin: "0 10px" }}>—</div>
              <DatePicker
                format="dd.MM.yy"
                style={{ width: 120 }}
                size="small"
                minDate={values.from}
                disableToolbar
                autoOk
                label="Дата, до"
                inputVariant="outlined"
                variant="inline"
                value={values.to}
                onChange={(date) => {
                  date.setHours(23);
                  date.setMinutes(59);
                  onChange({ to: new Date(date).getTime() });
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <br />
          <div
            className={classes.customVideoSectionTitle}
            style={{ marginTop: 20 }}
          >
            Поставщики:
          </div>
          <div className={classes.customVideoSection} style={{ height: 110 }}>
            <FormControlLabel
              label="Выбрать всех"
              control={
                <Checkbox
                  checked={values.providers.length === providers.length}
                  size="small"
                  onChange={() => {
                    if (values.providers.length === providers.length)
                      onChange({ providers: [] });
                    else onChange({ providers });
                  }}
                />
              }
            />
            {providers
              .filter((name) => name !== "RPLUMA" && name !== "IFRAME")
              .map((name) => (
                <FormControlLabel
                  label={name}
                  key={name}
                  control={
                    <Checkbox
                      checked={values.providers.includes(name)}
                      size="small"
                      onChange={() => {
                        if (values.providers.includes(name))
                          onChange({
                            providers: values.providers.filter(
                              (platform) => platform !== name
                            ),
                          });
                        else
                          onChange({ providers: [...values.providers, name] });
                      }}
                    />
                  }
                />
              ))}
          </div>
          {errorMessage && (
            <div style={{ color: "red", fontSize: 16, marginTop: 10 }}>
              {errorMessage}
            </div>
          )}
          <Box direction="row" style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onSubmit()}
              disabled={!isFormValid || isDownloadPending}
              style={{ marginRight: 10 }}
            >
              {isDownloadPending ? (
                <>
                  <CircularProgress size={15} style={{ marginRight: 5 }} /> Идет
                  формирование отчета...
                </>
              ) : (
                "Загрузить"
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => toggleReportDialog()}
            >
              Закрыть
            </Button>
          </Box>
        </DialogContent>

        <DialogActions disableSpacing></DialogActions>
      </Dialog>
    </div>
  );
};
