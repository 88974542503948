import { Paper as MuiPaper, Box, styled } from "@material-ui/core";

export const Paper = styled(MuiPaper)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
}));

export const Head = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 16px 4px 16px",
}));

export const Body = styled(Box)(() => ({
  padding: 16,
}));
