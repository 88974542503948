import React from "react";
import { UserCreate, UserEdit } from "./template";

export interface UserForm {
  userId?: string;
}

export function UserForm({ userId }: UserForm) {
  if (userId) return <UserEdit userId={userId} />;
  return <UserCreate />;
}
