import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import * as MatIcons from "@material-ui/icons";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { useStore } from "effector-react";
import React from "react";
import { dateLight } from "../../app/theme";
import * as Store from "../../stores/videos/store";
import { setDateFilter, setTimeFilter } from "./utils";
import * as Search from "../../stores/videos/search";
import * as Manual from "../../stores/videos/manual";

const TopControls = () => {
  const fetchParams = useStore(Store.$fetchParams);
  const providers = useStore(Store.$providers);

  let timeout = null;

  const handleTimeout = (event, key) => {
    clearTimeout(timeout);
    timeout = setTimeout(
      (term) => {
        Store.setFetchParams({ [key]: term });
      },
      500,
      event.target.value
    );
  };

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item style={{ marginTop: 10, marginBottom: 10 }}>
        <FormControlLabel
          label="Live"
          control={
            <Switch
              checked={fetchParams.live ? true : false}
              onChange={(event) =>
                event.target.checked
                  ? Store.setFetchParams({ live: true })
                  : Store.setFetchParams({ live: null })
              }
            />
          }
        />
      </Grid>
      <Grid item style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
        <TextField
          style={{ width: 150 }}
          size="small"
          label="ID"
          type="search"
          defaultValue={fetchParams.id}
          variant="outlined"
          color="secondary"
          onChange={(event) => handleTimeout(event, "id")}
        />
      </Grid>
      <Grid item style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
        <TextField
          style={{ width: 150 }}
          size="small"
          label="Название"
          type="search"
          defaultValue={fetchParams.name}
          variant="outlined"
          color="secondary"
          onChange={(event) => handleTimeout(event, "name")}
        />
      </Grid>
      <Grid item style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
        <TextField
          style={{ width: 150 }}
          size="small"
          label="Olimp ID"
          defaultValue={fetchParams.olimpId}
          type="search"
          variant="outlined"
          color="secondary"
          onChange={(event) => handleTimeout(event, "olimpId")}
        />
      </Grid>
      <Grid item style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
        <FormControl style={{ width: 120 }} size="small" variant="outlined">
          <InputLabel color="secondary">Привязан</InputLabel>
          <Select
            labelWidth={100}
            color="secondary"
            value={fetchParams.binded !== null ? fetchParams.binded : "Неважно"}
            onChange={(event) =>
              Store.setFetchParams({
                binded:
                  event.target.value === "Неважно" ? null : event.target.value,
              })
            }
          >
            <MenuItem value={"Неважно"}>Неважно</MenuItem>
            <MenuItem value={true}>Да</MenuItem>
            <MenuItem value={false}>Нет</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <ThemeProvider theme={dateLight}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid
                item
                style={{ marginLeft: 20, marginTop: 10, marginBottom: 10 }}
              >
                <DatePicker
                  format="dd.MM.yy"
                  style={{ width: 100 }}
                  size="small"
                  disableToolbar
                  autoOk
                  label="Дата"
                  inputVariant="outlined"
                  variant="inline"
                  value={fetchParams.start ? fetchParams.start[0] : null}
                  onChange={(date) => {
                    setDateFilter(date);
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
              >
                <TimePicker
                  views={["hours", "minutes"]}
                  ampm={false}
                  style={{ width: 75 }}
                  size="small"
                  disableToolbar
                  autoOk
                  label="От"
                  inputVariant="outlined"
                  variant="inline"
                  value={fetchParams.start ? fetchParams.start[0] : null}
                  onChange={(time) => {
                    setTimeFilter({
                      time1: time,
                      start: fetchParams.start ? fetchParams.start : 0,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
              >
                <TimePicker
                  views={["hours", "minutes"]}
                  ampm={false}
                  style={{ width: 75 }}
                  size="small"
                  disableToolbar
                  autoOk
                  label="До"
                  inputVariant="outlined"
                  variant="inline"
                  value={fetchParams.start ? fetchParams.start[1] : null}
                  onChange={(time) => {
                    setTimeFilter({
                      time2: time,
                      start: fetchParams.start ? fetchParams.start : 0,
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="menu"
                  onClick={() => {
                    Store.setFetchParams({ start: null });
                  }}
                >
                  <MatIcons.Cancel />
                </IconButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                label="Все поставщики"
                control={
                  <Checkbox
                    checked={fetchParams.source.length === providers.length}
                    indeterminate={
                      fetchParams.source.length < providers.length &&
                      fetchParams.source.length > 0
                    }
                    size="small"
                    onChange={(event) =>
                      event.target.checked
                        ? Store.setFetchParams({
                            source: Store.$providers.getState(),
                          })
                        : Store.setFetchParams({ source: [] })
                    }
                  />
                }
              />

              {providers.map((src) => (
                <FormControlLabel
                  label={src}
                  key={src}
                  control={
                    <Checkbox
                      checked={fetchParams.source.includes(src)}
                      size="small"
                      onChange={(event) => {
                        Search.setAnchorEl(null);
                        Manual.setAnchorEl(null);
                        let tempSourcesFilter = [...fetchParams.source];
                        event.target.checked
                          ? tempSourcesFilter.push(src)
                          : tempSourcesFilter.splice(
                              tempSourcesFilter.indexOf(src),
                              1
                            );
                        Store.setFetchParams({ source: tempSourcesFilter });
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopControls;
