import React, { FormEvent, useEffect } from "react";
import {
  TextField,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
} from "@material-ui/core";
import { FormGroup } from "../styled";
import { allUserRoles } from "../../../../constant";
import { closeModal } from "../../../../stores/modal";
import {
  $formState,
  formStateResetEv,
  formStateChangedEv,
  createUserFx,
} from "../../../../stores/users";
import { useStore } from "effector-react";
import { types } from "../../../../api";
import { toast } from "react-toastify";

export function UserCreate() {
  const values = useStore($formState);
  const isFormDisabled = () => {
    return (
      !values.password ||
      !values.userName ||
      !values.roles ||
      values.roles?.length === 0
    );
  };

  useEffect(() => {
    return () => formStateResetEv();
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createUserFx(values as types.UserCreateRequest);
      toast("Успешно создан", { type: "success" });
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeRoles = (role: string) => {
    const roles = [...(values.roles || [])];
    const findIndex = roles?.findIndex((r) => r === role);
    if (findIndex === -1) roles.push(role as never);
    else roles.splice(findIndex, 1);
    formStateChangedEv({ roles });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <TextField
          size="small"
          label="Логин"
          value={values.userName || ""}
          variant="outlined"
          onChange={(e) =>
            formStateChangedEv({ userName: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          size="small"
          label="Пароль"
          value={values.password || ""}
          variant="outlined"
          type="password"
          onChange={(e) =>
            formStateChangedEv({ password: e.currentTarget.value })
          }
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Роль</FormLabel>
        {allUserRoles.map((role) => (
          <FormControlLabel
            key={role}
            control={
              <Checkbox
                checked={(values?.roles || []).includes(role as never)}
                onChange={() => handleChangeRoles(role)}
              />
            }
            label={role}
          />
        ))}
      </FormGroup>
      <FormGroup>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button color="primary" fullWidth onClick={() => closeModal()}>
              Отменить
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              disabled={isFormDisabled()}
              type="submit"
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
}
