/** @format */

import * as Store from "../../stores/videos/store";
import * as Search from "../../stores/videos/search";
import * as Manual from "../../stores/videos/manual";
import * as Platform from "../../stores/videos/platform";
import * as Zone from "../../stores/videos/zone";
import * as Priority from "../../stores/videos/priority";

const locale = "ru";

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
};

const bothOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const offset = new Date().getTimezoneOffset() * 60000;

export const dateFormater = (date) =>
  Intl.DateTimeFormat(locale, dateOptions).format(date);
export const timeFormater = (time) =>
  Intl.DateTimeFormat(locale, timeOptions).format(time);
export const bothFormater = (time) =>
  Intl.DateTimeFormat(locale, bothOptions).format(time);

export const setDateFilter = (date) => {
  if (date) {
    let date1 =
      Math.trunc((date.valueOf() - offset) / 86400000) * 86400000 + offset;
    let date2 = date1 + 86400000 - 1;

    Store.setFetchParams({ start: [date1, date2] });
  } else {
    Store.setFetchParams({ start: null });
  }
};

export const setTimeFilter = ({ time1, time2, start }) => {
  time1
    ? Store.setFetchParams({ start: [time1.valueOf(), start[1]] })
    : time2
    ? Store.setFetchParams({ start: [start[0], time2.valueOf()] })
    : Store.setFetchParams({ start: null });
};

export const closeSearch = () => {
  Search.resetAnchorEl();
  Search.resetSearchParams();
  Search.resetSearchResults();
  Search.resetPost();
};

export const closePlatform = () => {
  Platform.resetAnchorEl();
  Platform.resetPost();
};

export const closeZone = () => {
  Zone.resetAnchorEl();
  Zone.resetPost();
};

export const closePriority = () => {
  Priority.resetAnchorEl();
  Priority.resetPost();
};

export const closeManual = () => {
  Manual.resetAnchorEl();
  Manual.resetPost();
};
