import React from "react";
import { Close } from "@material-ui/icons";
import {
  DialogContent,
  Dialog,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { useStore } from "effector-react";
import { $modal, closeModal } from "../../stores/modal";
import { Head, Body } from "./styled";

export function Modal() {
  const modal = useStore($modal);

  if (!modal) return null;

  return (
    <Dialog
      open={Boolean(modal)}
      onClose={() => closeModal()}
      maxWidth="xs"
      fullWidth
      {...modal?.options?.modalProps}
    >
      <DialogContent style={{ padding: 0 }}>
        {!!modal.options?.title && (
          <Head>
            {!!modal.options?.title && (
              <Box>
                <Typography variant="h5">{modal.options?.title}</Typography>
              </Box>
            )}
            {!modal.options?.shouldHideCloseButton && (
              <IconButton onClick={() => closeModal()}>
                <Close />
              </IconButton>
            )}
          </Head>
        )}
        <Body>{modal.element}</Body>
      </DialogContent>
    </Dialog>
  );
}
