import React, { useState } from "react";

import { useStore } from "effector-react";

import { Pagination } from "@material-ui/lab";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";

import * as Store from "../../stores/videos/store";

const handlePageChange = (t, p) => {
  Store.setFetchParams({ page: p });

  const anchor = (t.ownerDocument || document).querySelector(
    "#back-to-top-anchor"
  );

  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

const PageControls = () => {
  const pagesTotal = useStore(Store.$pagesTotal);
  const fetchParams = useStore(Store.$fetchParams);

  const [p, setP] = useState(fetchParams.page);

  React.useEffect(() => {
    setP(fetchParams.page);
  }, [fetchParams.page]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      style={{ marginTop: 10, marginBottom: 10 }}
    >
      <Grid item>
        <Pagination
          size="large"
          page={fetchParams.page}
          boundaryCount={1}
          siblingCount={2}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          count={pagesTotal}
          onChange={(event, page) => {
            handlePageChange(event, page);
          }}
        />
      </Grid>
      <Grid item>
        <FormControl
          style={{ width: 105, marginLeft: 10 }}
          size="small"
          variant="outlined"
        >
          <InputLabel color="secondary">Страница</InputLabel>
          <OutlinedInput
            type="number"
            labelWidth={70}
            color="secondary"
            value={p}
            onChange={(e) => setP(+e.target.value)}
            onKeyDown={(event) =>
              event.key === "Enter" &&
              handlePageChange(event.target, +event.target.value)
            }
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          style={{ width: 105, marginLeft: 10 }}
          size="small"
          variant="outlined"
        >
          <InputLabel color="secondary">На странице</InputLabel>
          <Select
            labelWidth={90}
            color="secondary"
            value={fetchParams.size}
            onChange={(event) =>
              Store.setFetchParams({ size: event.target.value })
            }
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PageControls;
