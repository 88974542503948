/** @format */

import React from "react";
import { useStore } from "effector-react";
import { styles } from "../../app/theme";
import * as Priority from "../../stores/videos/priority";
import * as Store from "../../stores/videos/store";
import * as Utils from "./utils";
import {
  Backdrop,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

const PriorityPopper = () => {
  const classes = styles();
  const anchorEl = useStore(Priority.$anchorEl);
  const index = useStore(Priority.$index);
  const priorities = useStore(Store.$priorities);
  const providersList = useStore(Store.$providers);
  const zoneProviders = priorities[index] || [];

  return (
    <Backdrop
      invisible={false}
      style={{ zIndex: 1301 }}
      open={Boolean(anchorEl)}
      onClick={Utils.closePriority}
    >
      <Popper
        onClick={(event) => event.stopPropagation()}
        style={{ zIndex: 1301 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper elevation={3} style={{ overflowY: "auto", padding: 10 }}>
          <List dense disablePadding>
            {providersList.filter((src) => !zoneProviders.includes(src)).length
              ? providersList
                  .filter((src) => !zoneProviders.includes(src))
                  .map((src) => (
                    <ListItem
                      disableGutters
                      onClick={() => {
                        Store.updatePriorities({
                          zone: index,
                          providers: [...zoneProviders, src],
                        });
                      }}
                      button
                      key={src}
                      className={classes.noPadding}
                    >
                      <ListItemText
                        className={classes.noMargin}
                        primary={`${src}`}
                      />
                    </ListItem>
                  ))
              : "Все доступные источники уже добавлены!"}
          </List>
        </Paper>
      </Popper>
    </Backdrop>
  );
};

export default PriorityPopper;
