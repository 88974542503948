import { Api } from "./generated";
import type * as types from "./generated";
import { toast } from "react-toastify";

const client = new Api({
  baseURL: "/",
});
const api = client.api;
const adminApi = client.admin;

client.instance.interceptors.response.use(
  (config) => config,
  (error) => {
    const message = error?.response?.data?.desc;
    console.log(message);
    if (message) toast(message, { type: "error" });
    return Promise.reject(error);
  }
);

export { api, adminApi, types };
