import {
  styled,
  FormGroup as OriginalFormGroup,
  Button,
} from "@material-ui/core";

export const FormGroup = styled(OriginalFormGroup)({
  "& + &": {
    marginTop: 16,
  },
});

export const BlockedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  "&:hover, &:focus": {
    backgroundColor: theme.palette.error.dark,
  },
}));
